import axios from "axios";
import React, { useEffect, useState } from "react";
import ProjectForm from "../forms/ProjectForm";
import ConfirmBox from "../popups/ConfirmBox";
import MessageBox from "../popups/MessageBox";
import Spinner from "../../components/Spinner";
import { Link } from "react-router-dom";

const ProjectsList = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [action, setAction] = useState("");
  const [updateMode, setUpdateMode] = useState("");

  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    setIsLoading(true);
    setUpdateMode(false);

    const fetchProjects = async () => {
      try {
        const { data } = await axios.get("/api/projects");
        setProjects(data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchProjects();
  }, []);

  const askConfirmation = async () => {
    setConfirmBoxVisible(true);
  };

  const deleteProject = async () => {
    try {
      const response = await axios.delete(`/api/projects/${selectedProject.id}`);
      if (response.status === 200) {
        setConfirmBoxVisible(false);
        setMessageBoxVisible(true);
        setMessageType("success");
        setMessage("Le projet a bien été supprimé.");
      }
    } catch (err) {
      console.log(err);
      setConfirmBoxVisible(false);
      setMessageBoxVisible(true);
      setMessageType("error");
      setMessage("Une erreur est survenue.");
    }
  };

  return (
    <div className="objects-list">
      {confirmBoxVisible && (
        <ConfirmBox
          message={"Supprimer ce Projet ?"}
          onConfirm={deleteProject}
          onCancel={(e) => setConfirmBoxVisible(false)}
        />
      )}

      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);
            window.location.reload(true);
          }}
        />
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {projects.map((project, index) => {
            return (
              <div
                key={index}
                className={selectedProject.id === project.id && updateMode ? "list-item selected" : "list-item"}
                value={project.id}
              >
                <h3>{project.title}</h3>

                {selectedProject.id === project.id && updateMode ? (
                  <ProjectForm project={selectedProject} action="update" />
                ) : (
                  <div className="buttons-container">
                    <button
                      value="update"
                      className="send-btn"
                      onClick={(e) => {
                        setAction(e.target.value);
                        setSelectedProject(project);
                        setUpdateMode(true);
                      }}
                    >
                      modifier
                    </button>
                    <button
                      value="delete"
                      className="delete-btn"
                      onClick={(e) => {
                        setAction(e.target.value);
                        setSelectedProject(project);
                        askConfirmation();
                      }}
                    >
                      supprimer
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </>
      )}

      <ProjectForm action="add" />
    </div>
  );
};

export default ProjectsList;
