const CreateFormData = async (files) => {
  const data = new FormData();
  const maxAllowedSize = 5 * 1024 * 1024;
  const notUploaded = [];

  for (var i = 0; i < files.length; i++) {
    if (files[i].size > maxAllowedSize) {
      notUploaded.push(files[i]);
      alert(`L'image "${files[i].name}" est supérieure à 5MB. Elle ne sera pas uploadée.`);
    } else {
      data.append("images", files[i]);
    }
  }
  return data;
};

export default CreateFormData;
