import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashArrowUp, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import MessageBox from "../popups/MessageBox";
import Spinner from "../Spinner";
import CreateFormData from "../../utils/CreateFormData";

const PartnerForm = ({ partner, action }) => {
  const navigate = useNavigate();

  const [name, setName] = useState(partner?.name);
  const [logoUrl, setLogoUrl] = useState(partner?.logo_url);
  const [websiteUrl, setWebsiteUrl] = useState(partner?.website_url);

  const [files, setFiles] = useState("");
  const [deleteImage, setDeleteImage] = useState(false);

  const [validationErrors, setValidationErrors] = useState([]);
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const uploadImages = async (partnerId) => {
    try {
      if (partnerId) {
        const data = await CreateFormData(files);

        const response = await axios.post(`/api/partners/${partnerId}/upload`, data);
        if (response.status === 200) {
          showSuccessBox("Le partenaire a bien été sauvegardé.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const addPartner = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.post("/api/partners", { name, logo_url: logoUrl, website_url: websiteUrl });
      const partnerId = response.data.newPartner.id;

      if (files?.length !== 0) {
        await uploadImages(partnerId);
      } else {
        if (response.status === 200) {
          showSuccessBox("Le partenaire a bien été sauvegardé.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const updatePartner = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.patch(`/api/partners/${partner.id}`, {
        name,
        logo_url: logoUrl,
        website_url: websiteUrl,
      });

      if (files?.length !== 0) {
        await uploadImages(partner.id);
      } else {
        if (response.status === 200) {
          showSuccessBox("Le partenaire a bien été sauvegardé.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = async (err) => {
    setIsRequestLoading(false);
    console.log(err);
    if (err.response.data.errors) setValidationErrors(err.response.data.errors);
    else {
      navigate("/internal-error");
      showErrorBox("Une erreur est survenue.");
    }
  };

  const showSuccessBox = async (msg) => {
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("success");
    setMessage(msg);
  };

  const showErrorBox = async (msg) => {
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("error");
    setMessage(msg);
  };

  return (
    <>
      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);

            if (messageType === "success") {
              /* redirect to commitee page */
              navigate("/commitee");
              window.location.reload(true);
            } else if (messageType === "error") {
              window.location.reload(true);
            }
          }}
        />
      )}

      <form className="edit-form">
        {action === "add" && <h3>Nouveau Partenaire</h3>}

        <div className="inputs-container">
          <label className="required" htmlFor="name">
            Nom
          </label>
          <input
            type="text"
            name="name"
            defaultValue={action === "update" ? partner.name : ""}
            placeholder={action === "update" ? partner.name : "Entrez le nom du partenaire"}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />

          <label htmlFor="website_url">URL du site</label>
          <input
            type="url"
            name="website_url"
            defaultValue={action === "update" ? partner.website_url : ""}
            placeholder={action === "update" ? partner.website_url : "Entrez l'URL du site du partenaire"}
            onChange={(e) => {
              setWebsiteUrl(e.target.value);
            }}
          />

          <label htmlFor="images">Logo</label>
          <div className="thumb-overview">
            {partner?.thumb_url && (
              <div className="thumb-box">
                <img alt="" src={partner.thumb_url} className={deleteImage ? "image-to-delete" : ""} />
                <button
                  className="delete-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!deleteImage) {
                      setDeleteImage(true);
                    } else {
                      setDeleteImage(false);
                    }
                    console.log(deleteImage);
                  }}
                >
                  {deleteImage ? <FontAwesomeIcon icon={faTrashArrowUp} /> : <FontAwesomeIcon icon={faTrash} />}
                </button>
              </div>
            )}
          </div>

          <p>
            <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> Le fichier doit être inférieur à 5MB.
          </p>
          <p>
            <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> Un partenaire ne peut stocker qu'une seule
            image.
          </p>

          <input
            type="file"
            name="images"
            accept=".jpg, .jpeg, .png"
            onChange={(e) => {
              setFiles(e.target.files);
            }}
          />

          {/* 
          <label htmlFor="logo_url">URL du logo</label>
          <input
            type="url"
            name="logo_url"
            defaultValue={action === "update" ? partner.logo_url : ""}
            placeholder={action === "update" ? partner.logo_url : "Entrez l'URL du logo du partenaire"}
            onChange={(e) => {
              setLogoUrl(e.target.value);
            }}
          /> */}
        </div>

        {validationErrors.length !== 0 && (
          <div className="validation-errors">
            {validationErrors.map((error, index) => (
              <p key={index} className="wrong-message">
                {error.message}
              </p>
            ))}
          </div>
        )}

        {action === "add" && (
          <button value="ajouter" type="submit" className="add-btn" onClick={addPartner}>
            {isRequestLoading ? <Spinner size="small" /> : "ajouter"}
          </button>
        )}
      </form>

      {action === "update" && (
        <button value="update" type="submit" className="send-btn white-btn" onClick={updatePartner}>
          {isRequestLoading ? <Spinner size="small" /> : "sauvegarder"}
        </button>
      )}
    </>
  );
};

export default PartnerForm;
