import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MessageBox from "../components/popups/MessageBox";

const LoginPage = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [validationErrors, setValidationErrors] = useState([]);
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const login = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/users/login", {
        email,
        password,
      });

      if (response.status === 200) {
        setMessageBoxVisible(true);
        setMessageType("success");
        setMessage("Connexion réussie.");
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.errors) setValidationErrors(err.response.data.errors);
      else {
        setMessageBoxVisible(true);
        setMessageType("error");
        setMessage("Une erreur est survenue.");
        navigate("/internal-error");
      }
    }
  };

  return (
    <div className="login page">
      <h2 className="pagetitle">Se Connecter</h2>

      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onTimeout={(e) => {
            setMessageBoxVisible(false);
            /* redirect to home page */
            navigate("/");
            window.location.reload(true);
          }}
        />
      )}

      <form className="login-form">
        <div className="form-element">
          <label htmlFor="email">Email</label>
          <input
            name="email"
            type="email"
            placeholder="Votre email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
          ></input>
        </div>

        <div className="form-element">
          <label htmlFor="password">Mot de Passe</label>
          <input
            name="password"
            type="password"
            placeholder="Votre mot de passe"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          ></input>
        </div>
      </form>

      {validationErrors.length !== 0 && (
        <div className="validation-errors">
          {validationErrors.map((error, index) => (
            <p key={index} className="wrong-message">
              {error.message}
            </p>
          ))}
        </div>
      )}

      <button className="  send-btn" onClick={login}>
        se connecter
      </button>

      <Link to="/forgot-password" className="blue-link">
        Mot de Passe Oublié ?
      </Link>
    </div>
  );
};

export default LoginPage;
