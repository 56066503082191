import { useEffect, useLayoutEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// const withRouter = (Component) => {
//   const ComponentWithRouterProp = (props) => {
//     let location = useLocation();
//     let navigate = useNavigate();
//     let params = useParams();
//     return <Component {...props} router={{ location, navigate, params }} />;
//   };

//   return ComponentWithRouterProp;
// };

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    const canControlScrollRestoration = "scrollRestoration" in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = "manual";
    }

    document.getElementById("root").scrollTo(0, 0);
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
