import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const DocumentsList = ({ docs, club, onClose }) => {
  console.log(club);

  return (
    <div className="shadow-bg">
      <div className="documents-list modal">
        <div className="modal-header">
          <h3>Documents - {club.name}</h3>
          <button onClick={onClose} className="delete-btn xmark">
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <ul>
          {docs.map((doc, index) => {
            return (
              <li key={index}>
                <a href={doc.doc_url} className="blue-link" target="_blank" rel="noreferrer">
                  {doc.doc_title}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default DocumentsList;
