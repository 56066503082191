import React from "react";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const VideoPlayer = ({ object, onClose }) => {
  return (
    <div className="shadow-bg">
      <div className="video-player modal">
        <div className="player-header">
          <h3>{object.name}</h3>
          <button onClick={onClose} className="delete-btn xmark">
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <ReactPlayer className="react-player" url={object.video_url} controls />
      </div>
    </div>
  );
};

export default VideoPlayer;
