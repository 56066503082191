import React from "react";
import Carousel from "nuka-carousel";
import { AdvancedImage } from "@cloudinary/react";
import { Cloudinary, Transformation, CloudinaryImage } from "@cloudinary/url-gen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const Slideshow = ({ slides }) => {
  if (slides)
    return (
      <div className="slideshow">
        <Carousel
          enableKeyboardControls={true}
          defaultControlsConfig={{
            nextButtonText: ">",
            prevButtonText: "<",
          }}
          renderCenterLeftControls={({ previousSlide, currentSlide }) => (
            <button className={currentSlide === 0 ? "inactive" : ""} onClick={previousSlide}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
          )}
          renderCenterRightControls={({ nextSlide, currentSlide }) => (
            <button className={currentSlide === slides.length - 1 ? "inactive" : ""} onClick={nextSlide}>
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          )}
        >
          {slides?.map((slide, index) => {
            return <img src={slide.image_url} alt="" className="slide" key={index} />;
          })}
        </Carousel>
      </div>
    );
};

export default Slideshow;
