import "./styles.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ReactSession } from "react-client-session";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import CommiteePage from "./pages/CommiteePage";
import EventsPage from "./pages/EventsPage";
import ClubsPage from "./pages/ClubsPage";
import ContactPage from "./pages/ContactPage";
import LoginPage from "./pages/LoginPage";
import EditPage from "./pages/EditPage";
import ProjectPage from "./pages/ProjectPage";
import ErrorPage from "./pages/ErrorPage";
import ProfilePage from "./pages/ProfilePage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import LegalsPage from "./pages/LegalsPage";
import ConfidentialityPage from "./pages/ConfidentialityPage";

function App() {
  const API_URL = "https://api.sportspourtous49.org";

  const [loginStatus, setLoginStatus] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = API_URL;

  ReactSession.setStoreType("localStorage");

  /* runs only once! */
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get("/api/users/login");
        setLoginStatus(response.data.loggedIn);
        setCurrentUser(response.data.user);
        ReactSession.set("user", response.data.user);
      } catch (err) {
        console.log(err);
      }
    };
    checkLoginStatus();
  }, []);

  return (
    <>
      <Header loggedIn={loginStatus} currentUser={ReactSession.get("user") || currentUser || {}} />
      <main>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/commitee" element={<CommiteePage />} exact />
          <Route path="/events" element={<EventsPage />} exact />
          <Route path="/clubs" element={<ClubsPage />} exact />
          <Route path="/contact" element={<ContactPage />} exact />
          <Route path="/projects/:id" element={<ProjectPage />} />

          <Route path="/login" element={<LoginPage />} exact />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} exact />
          <Route path="/reset-password" element={<ResetPasswordPage />} exact />

          <Route path="/legals" element={<LegalsPage />} exact />
          <Route path="/confidentiality" element={<ConfidentialityPage />} exact />

          <Route
            path="/profile"
            element={
              <ProfilePage
                loggedIn={loginStatus}
                currentUser={ReactSession.get("user") ? ReactSession.get("user") : {}}
              />
            }
            exact
          />
          <Route
            path="/edit"
            element={
              <EditPage loggedIn={loginStatus} currentUser={ReactSession.get("user") ? ReactSession.get("user") : {}} />
            }
            exact
          />

          <Route
            path="/internal-error"
            element={<ErrorPage message="Erreur Interne. Veuillez réessayer plus tard." code="500" />}
          />
          <Route path="*" element={<ErrorPage message="Cette page n'existe pas." code="404" />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
}

export default App;
