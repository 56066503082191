import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashArrowUp, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import MessageBox from "../popups/MessageBox";
import Spinner from "../Spinner";
import CreateFormData from "../../utils/CreateFormData";

const EventForm = ({ event, action }) => {
  const navigate = useNavigate();

  const [title, setTitle] = useState(event?.title);
  const [body, setBody] = useState(event?.body);
  const [date, setDate] = useState(event?.date);
  const [isPinned, setIsPinned] = useState(event?.pinned);
  const [docUrl, setDocUrl] = useState(event?.doc_url);
  const [videoUrl, setVideoUrl] = useState(event?.video_url);
  const [articleUrl, setArticleUrl] = useState(event?.article_url);
  const [programUrl, setProgramUrl] = useState(event?.program_url);

  const [files, setFiles] = useState("");
  const [imagesToDeleteArray, setImagesToDeleteArray] = useState([]);

  const [validationErrors, setValidationErrors] = useState([]);
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const uploadImages = async (eventId) => {
    try {
      if (eventId) {
        const data = await CreateFormData(files);

        const response = await axios.post(`/api/events/${eventId}/upload`, data);
        if (response.status === 200) {
          showSuccessBox("L'actualité a bien été sauvegardée.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const addEvent = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.post("/api/events", {
        title,
        body,
        date,
        pinned: isPinned,
        doc_url: docUrl,
        video_url: videoUrl,
        article_url: articleUrl,
        program_url: programUrl,
      });

      const eventId = response.data.newEvent.id;

      if (files?.length !== 0) {
        await uploadImages(eventId);
      } else {
        if (response.status === 200) {
          showSuccessBox("L'actualité a bien été sauvegardée.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const updateEvent = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.patch(`/api/events/${event.id}`, {
        title,
        body,
        date,
        pinned: isPinned,
        doc_url: docUrl,
        video_url: videoUrl,
        article_url: articleUrl,
        program_url: programUrl,
        imagesToDelete: imagesToDeleteArray,
      });

      if (files?.length !== 0) {
        await uploadImages(event.id);
      } else {
        if (response.status === 200) {
          showSuccessBox("L'actualité a bien été sauvegardée.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = async (err) => {
    setIsRequestLoading(false);
    console.log(err);
    if (err.response.data.errors) setValidationErrors(err.response.data.errors);
    else {
      showErrorBox("Une erreur est survenue.");
    }
  };

  const showSuccessBox = async (msg) => {
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("success");
    setMessage(msg);
  };

  const showErrorBox = async (msg) => {
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("error");
    setMessage(msg);
  };

  return (
    <>
      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);

            if (messageType === "success") {
              /* redirect to events page */
              navigate("/events");
              window.location.reload(true);
            } else if (messageType === "error") {
              window.location.reload(true);
            }
          }}
        />
      )}

      <form className="edit-form">
        {action === "add" && <h3>Nouvelle Actualité</h3>}

        <div className="inputs-container">
          <label className="required" htmlFor="title">
            Titre
          </label>
          <input
            type="text"
            name="title"
            defaultValue={action === "update" ? event.title : ""}
            placeholder={action === "update" ? event.title : "Entrez un titre"}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />

          <label className="required" htmlFor="date">
            Date
          </label>
          <p>
            <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> La date doit être au format dd/mm/yyyy.
          </p>
          <input
            type="text"
            name="date"
            defaultValue={action === "update" ? new Date(event.date).toLocaleDateString("fr-FR") : ""}
            placeholder={action === "update" ? new Date(event.date).toLocaleDateString("fr-FR") : "dd/mm/yyyy"}
            onChange={(e) => {
              const [day, month, year] = e.target.value.split("/");
              const formattedDate = month + "/" + day + "/" + year;
              setDate(formattedDate);
            }}
          />

          <label className="required" htmlFor="event_body">
            Contenu
          </label>
          <textarea
            name="event_body"
            defaultValue={action === "update" ? event.body : ""}
            placeholder={action === "update" ? event.body : "Entrez le contenu de l'article ici"}
            onChange={(e) => {
              setBody(e.target.value);
            }}
          />

          <div className="form-row check">
            <label htmlFor="pin">Épingler vers le haut</label>
            <input
              type="checkbox"
              name="pin"
              defaultChecked={action === "update" ? event.pinned && true : false}
              onChange={(e) => {
                if (e.target.checked) {
                  setIsPinned(1);
                } else setIsPinned(0);
              }}
            />
          </div>

          <hr />

          <label htmlFor="doc_url">URL d'un document, PDF</label>
          <input
            type="url"
            name="doc_url"
            defaultValue={action === "update" ? event.doc_url : ""}
            placeholder={action === "update" ? event.doc_url : "Entrez l'URL du document"}
            onChange={(e) => {
              setDocUrl(e.target.value);
            }}
          />

          <label htmlFor="video_url">URL d'une vidéo</label>
          <input
            type="url"
            name="video_url"
            defaultValue={action === "update" ? event.video_url : ""}
            placeholder={action === "update" ? event.video_url : "Entrez l'URL de la vidéo"}
            onChange={(e) => {
              setVideoUrl(e.target.value);
            }}
          />

          <label htmlFor="article_url">URL d'un article</label>
          <input
            type="url"
            name="article_url"
            defaultValue={action === "update" ? event.article_url : ""}
            placeholder={action === "update" ? event.article_url : "Entrez l'URL de l'article"}
            onChange={(e) => {
              setArticleUrl(e.target.value);
            }}
          />

          <label htmlFor="program_url">URL d'une émission</label>
          <input
            type="url"
            name="program_url"
            defaultValue={action === "update" ? event.program_url : ""}
            placeholder={action === "update" ? event.program_url : "Entrez l'URL de l'émission"}
            onChange={(e) => {
              setProgramUrl(e.target.value);
            }}
          />

          <label htmlFor="images">Images</label>
          <div className="thumb-overview">
            {event?.images.map((image, index) => {
              return (
                <div key={index} className="thumb-box">
                  <img
                    alt=""
                    src={image.thumb_url}
                    className={imagesToDeleteArray.includes(image) ? "image-to-delete" : ""}
                  />
                  <button
                    className="delete-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      if (imagesToDeleteArray.includes(image)) {
                        setImagesToDeleteArray(imagesToDeleteArray.filter((item) => item.id !== image.id));
                      } else {
                        setImagesToDeleteArray((imagesToDeleteArray) => [...imagesToDeleteArray, image]);
                      }
                    }}
                  >
                    {imagesToDeleteArray.includes(image) ? (
                      <FontAwesomeIcon icon={faTrashArrowUp} />
                    ) : (
                      <FontAwesomeIcon icon={faTrash} />
                    )}
                  </button>
                </div>
              );
            })}
          </div>

          <p>
            <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> Les fichiers doivent être inférieurs à 5MB.
          </p>

          <input
            type="file"
            name="images"
            multiple
            accept=".jpg"
            onChange={(e) => {
              setFiles(e.target.files);
            }}
          />
        </div>

        {validationErrors.length !== 0 && (
          <div className="validation-errors">
            {validationErrors.map((error, index) => (
              <p key={index} className="wrong-message">
                {error.message}
              </p>
            ))}
          </div>
        )}

        {action === "add" && (
          <button value="ajouter" type="submit" className="add-btn" onClick={addEvent}>
            {isRequestLoading ? <Spinner size="small" /> : "ajouter"}
          </button>
        )}
      </form>

      {action === "update" && (
        <button value="update" type="submit" className="send-btn white-btn" onClick={updateEvent}>
          {isRequestLoading ? <Spinner size="small" /> : "sauvegarder"}
        </button>
      )}
    </>
  );
};

export default EventForm;
