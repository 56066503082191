import React from "react";

const ConfidentialityPage = () => {
  return (
    <div className="confidentiality page">
      <h2>Politique de Confidentialité</h2>
      <hr />

      <section className="confidentiality-content">
        <p>
          En conformité avec la réglementation applicable et notamment le Règlement (UE) n° 2016/679 du 27 avril 2016
          dit "Règlement Général sur la Protection des Données" ou "RGPD" et la loi n° 78-17 du 6 janvier 1978 dite "Loi
          Informatique et Liberté" modifiée, nous nous engageons à protéger votre vie privée et à traiter vos données de
          manière responsable. Cette politique de confidentialité décrit comment nous collectons, utilisons et
          partageons vos données lorsque vous utilisez notre site web.
        </p>

        <hr />
        <h3>Collecte de vos données</h3>
        <p>Nous collectons les données suivantes vous concernant :</p>

        <h4>Cookies de session</h4>
        <p>
          Les cookies de session sont des cookies qui sont utilisés pour suivre l'état de la session d'un utilisateur
          sur un site Web. Ils sont généralement utilisés pour maintenir l'état de la connexion d'un utilisateur à un
          site Web, par exemple lorsqu'un utilisateur s'identifie sur un site avec un nom d'utilisateur et un mot de
          passe.
        </p>
        <p>
          Le Comité Départemental Sports Pour Tous Maine-et-Loire n’utilise pas les cookies de session pour stocker des
          informations de manière permanente sur l'ordinateur de l'utilisateur et ces derniers sont effacés lorsque
          l'utilisateur ferme son navigateur ou se déconnecte.
        </p>
        <p>Vous n’êtes pas concerné·e si vous ne possédez pas d’identifiants sur notre site.</p>

        <h4>Formulaire de contact</h4>
        <p>
          Les informations collectées via le formulaire de contact sont votre état civil : prénom, nom, email, et toute
          information que vous indiqueriez dans la zone de message libre.
        </p>
        <p>
          Le caractère obligatoire ou facultatif de la saisie des données est précisé lors de la collecte, par une
          astérisque apposée à côté des données à renseigner obligatoirement. La communication obligatoire de certaines
          de vos données personnelles est nécessaire au Comité Départemental Sports Pour Tous Maine-et-Loire pour
          pouvoir répondre aux questions ou aux demandes des utilisateurs.
        </p>

        <h4>Cookies de service tiers</h4>
        <p>
          Il est possible que des services tiers utilisés sur ce site (tels que les liens de vidéo Youtube ou les
          widgets de réseaux sociaux) déposent des cookies de type cookies de pistage intersite, auxquels nous n’avons
          pas accès.
        </p>
        <p>
          Un cookie de pistage intersite est un cookie qui est utilisé pour suivre les activités des utilisateurs sur
          plusieurs sites Web. Ces cookies sont généralement déposés par des réseaux publicitaires ou des sociétés de
          suivi du comportement en ligne, et peuvent être utilisés pour cibler les annonces publicitaires en fonction
          des intérêts des utilisateurs ou pour suivre leur comportement sur différents sites.
        </p>

        <p>
          Vous pouvez choisir de désactiver les cookies de pistage intersite en modifiant les paramètres de votre
          navigateur. La plupart des navigateurs vous permettent de refuser tous les cookies ou de sélectionner
          uniquement les cookies de sites Web spécifiques.
          <br /> Désactiver sur 
          <a
            href="https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur"
            target="_blank"
            rel="noreferrer"
            className="blue-link"
          >
            Firefox
          </a>
          .
          <br /> Désactiver sur 
          <a
            href="https://support.google.com/chrome/answer/2790761?hl=fr&co=GENIE.Platform%3DDesktop"
            target="_blank"
            rel="noreferrer"
            className="blue-link"
          >
            Chrome
          </a>
          .
        </p>

        <hr />
        <h3>Utilisation de vos données</h3>
        <p>Voici les informations concernant l'utilisation de vos données sur notre site :</p>

        <h4>Durée de conservation de vos données personnelles</h4>
        <p>
          Vos données personnelles collectées par le biais du formulaire de contact seront conservées pendant un délai
          de cinq (5) ans. Dans l’hypothèse où le Comité Départemental Sports Pour Tous Maine-et-Loire et vous entreriez
          dans une relation contractuelle, vos données seront conservées pendant la durée nécessaire à la bonne gestion
          de notre relation contractuelle puis archivées pendant la durée légale de prescription aux fins de preuve pour
          la constatation, l’exercice ou la défense d’un droit en justice.
        </p>

        <h4>Destinataires de vos données personnelles</h4>
        <p>
          Vos données personnelles sont strictement confidentielles et destinées exclusivement au Comité Départemental
          Sports Pour Tous Maine-et-Loire.
        </p>
        <p>
          Sauf obligation légale ou judiciaire lui enjoignant de le faire, le Comité Départemental Sports Pour Tous
          Maine-et-Loire ne divulguera, cédera, louera ou transmettra jamais vos données personnelles à des tiers autres
          que l’hébergeur du site, tel que mentionné dans les mentions légales du site, aux fins d’exécution des
          prestations techniques d’hébergement et de gestion des bases de données et uniquement dans les conditions
          contractuelles signées avec l’hébergeur qui ne sauraient déroger au présent article.
        </p>

        <h4>Mesures de sécurité mises en œuvre</h4>
        <p>
          Nous nous engageons à assurer la sécurité et l’intégrité de vos données personnelles. À ce titre, Le Comité
          Départemental Sports Pour Tous Maine-et-Loire met en œuvre et maintient des mesures techniques et
          organisationnelles de sécurité du Site et de son système d’information adaptées au regard de la nature de vos
          données personnelles et des risques présentés par leur traitement. Ces mesures visent à protéger vos données
          personnelles contre leur destruction, perte, altération, divulgation à des tiers non autorisés, et assurer le
          rétablissement de la disponibilité de vos données personnelles et l’accès à celles-ci dans des délais
          appropriés en cas d’incident physique ou technique.
        </p>

        <hr />
        <h3>Vos droits sur vos données personnelles</h3>
        <p>Vous disposez, à tout moment, des droits suivants sur vos données personnelles :</p>

        <h4>Droit d’accès</h4>
        <p>
          Obtenir la confirmation du traitement de vos données personnelles ainsi qu’un certain nombre d’informations
          sur les traitements, ces informations étant données dans la présente Politique de Confidentialité.
        </p>

        <h4>Droit de rectification</h4>
        <p>Obtenir la rectification de vos données personnelles lorsqu’elles sont inexactes ou incomplètes. </p>

        <h4>Droit à l’effacement</h4>
        <p>
          Obtenir l’effacement de vos données personnelles lorsqu’elles ne sont plus nécessaires au regard des finalités
          pour lesquelles elles ont été collectées ou que vous vous opposez au traitement de vos données personnelles.
        </p>

        <h4>Droit à la limitation du traitement</h4>
        <p>
          Obtenir la limitation du traitement de vos données personnelles lorsque vous contestez l’exactitude des
          données, lorsque le délai de conservation des données est arrivé à son terme mais que vous avez encore besoin
          de conserver ces données personnelles pour la constatation, l’exercice ou la défense d’un droit en justice, ou
          si vous vous êtes opposé·e au traitement.
        </p>

        <h4>Droit à la portabilité</h4>
        <p>
          Obtenir la communication des données personnelles que vous avez communiquées au Comité Départemental Sports
          Pour Tous Maine-et-Loire dans un format structuré, couramment utilisé et lisible par machine, et de les
          transmettre à un autre responsable du traitement.
        </p>

        <h4>Droit d’opposition au traitement</h4>
        <p>
          Vous opposer à tout moment, pour des motifs tenant à votre situation personnelle, au traitement de vos données
          personnelles, notamment dans le cas où cette opposition concerne de la prospection commerciale, y compris le
          profilage.
        </p>

        <h4>Droit de retrait du consentement</h4>
        <p>
          Retirer votre consentement au traitement futur de vos données personnelles par le Comité Départemental Sports
          Pour Tous Maine-et-Loire.
        </p>

        <h4>Droit de saisir l’autorité de contrôle compétente </h4>
        <p>
          Introduire une réclamation auprès de la Commission Nationale de l’Informatique et des Libertés ou de déposer
          une plainte auprès d'une autorité de contrôle si vous considérez que le traitement opéré par le Comité
          Départemental Sports Pour Tous Maine-et-Loire constitue une violation de vos données personnelles.
        </p>

        <hr />
        <h3>En savoir plus ?</h3>

        <ul>
          <li>
            <b>Commission nationale de l'informatique et des libertés (CNIL) : </b>
            <a href="https://www.cnil.fr/" target="_blank" rel="noreferrer" className="blue-link">
              https://www.cnil.fr/
            </a>
            <br />
            La CNIL est l'autorité de protection des données en France. Elle propose de nombreuses ressources sur la
            protection des données, notamment des guides et des conseils pratiques pour les entreprises et les
            organisations.
          </li>

          <li>
            <b>Center for Democracy and Technology (CDT) : </b>
            <a
              href="https://cdt.org/area-of-focus/privacy-data/"
              target="_blank"
              rel="noreferrer"
              className="blue-link"
            >
              https://cdt.org/area-of-focus/privacy-data/
            </a>
            <br /> Le CDT est une organisation à but non lucratif qui s'efforce de promouvoir la vie privée et la
            liberté en ligne.
          </li>

          <li>
            <b>Le Règlement général sur la protection des données (RGPD) : </b>
            <a
              href="https://www.economie.gouv.fr/entreprises/reglement-general-sur-protection-des-donnees-rgpd"
              target="_blank"
              rel="noreferrer"
              className="blue-link"
            >
              https://www.economie.gouv.fr/entreprises/reglement-general-sur-protection-des-donnees-rgpd
            </a>
            <br /> Le RGPD est un règlement de l'Union européenne qui a été adopté en 2016 et qui est entré en vigueur
            en 2018. Il a pour objectif de renforcer la protection des données personnelles des citoyens de l'Union
            européenne et de garantir que ces données sont traitées de manière équitable et transparente.
          </li>

          <li>
            <b>Le site de l'association Solidarité Numérique</b> : 
            <a href="https://www.solidarite-numerique.fr/" target="_blank" rel="noreferrer" className="blue-link">
              https://www.solidarite-numerique.fr/
            </a>
            <br /> Si vous souhaitez en savoir plus sur les enjeux de l'inclusion numérique en France et sur les actions
            menées pour y remédier, l'association Solidarité Numérique a pour mission de promouvoir l'inclusion
            numérique en France et de lutter contre la fracture numérique. Leur site est une plateforme d’aide au
            service des citoyens pour tous leurs besoins numériques
          </li>
        </ul>
      </section>
    </div>
  );
};

export default ConfidentialityPage;
