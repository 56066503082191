import { Link } from "react-router-dom";
import React from "react";

const LegalsPage = () => {
  return (
    <div className="legals page">
      <h2>Mentions Légales</h2>
      <hr />
      <section className="legals-content">
        <div className="inline-legals">
          <h4>Nom du site</h4>
          <p>Comité Départemental Sports Pour Tous Maine-et-Loire</p>
        </div>

        <div className="inline-legals">
          <h4>Adresse du site</h4>
          <p>
            <a className="blue-link" href="https://sportspourtous49.org">
              https://sportspourtous49.org
            </a>
          </p>
        </div>

        <div className="inline-legals">
          <h4>Hébergeur du site</h4>
          <p>o2switch, SAS au capital de 100 000 euros</p>
        </div>

        <div className="inline-legals">
          <h4>Adresse de l'hébergeur : </h4>
          <p>Chemin des Pardiaux 63000 Clermont-Ferrand</p>
        </div>

        <hr />

        <h4>Protection des données personnelles</h4>
        <p>
          Les utilisateurs du site sont tenus de respecter les dispositions de la loi relative à l'informatique, aux
          fichiers et aux libertés, dont la violation est passible de sanctions pénales. Ils doivent notamment
          s'abstenir, s'agissant des informations nominatives auxquelles ils accèdent, de toute collecte, de toute
          utilisation détournée, et d'une manière générale, de tout acte susceptible de porter atteinte à la vie privée
          ou à la réputation des personnes.
        </p>

        <h4>Propriété intellectuelle</h4>
        <p>
          Le contenu du site, incluant, de manière non limitative, les textes, images, graphismes, logo, icônes, sons,
          logiciels, est la propriété exclusive du Comité Sports Pour Tous Maine-et-Loire ou de tiers ayant autorisé le
          Comité à utiliser ces éléments.
          <br />
          En tant qu'utilisateur du site, vous êtes autorisé à accéder et à utiliser le contenu du site uniquement à des
          fins personnelles et non commerciales. Toute reproduction, distribution, modification, adaptation,
          retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans
          l'accord préalable écrit du Comité.
          <br />
          Ce site peut également comprendre des liens hypertextes vers d'autres sites qui ne sont pas édités par le
          Comité Sports Pour Tous Maine-et-Loire. Dans ces cas, le Comité ne peut en aucun cas être tenu responsable du
          contenu des sites liés, ni des éventuelles collectes de données effectuées par ces sites.
        </p>

        <h4>Gestion des cookies</h4>
        <p>
          Un cookie est un petit fichier enregistré sur votre ordinateur lors de votre visite sur un site internet. Il
          permet de conserver certaines informations vous concernant, telles que vos préférences de navigation ou votre
          panier d'achat, afin de faciliter votre navigation ultérieure sur un site.
          <br />
          Pour en savoir plus sur l'utilisation des cookies sur le site du Comité Sports Pour Tous Maine-et-Loire
          veuillez vous référer à notre{" "}
          <Link tabIndex="0" to="/confidentiality" className="blue-link">
            Politique de Confidentialité
          </Link>
          .
        </p>

        <h4>Modification des mentions légales</h4>
        <p>
          Le Comité Sports Pour Tous Maine-et-Loire se réserve le droit de modifier à tout moment les présentes mentions
          légales. Nous vous invitons donc à les consulter régulièrement.
        </p>
      </section>
    </div>
  );
};

export default LegalsPage;
