import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashArrowUp, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import MessageBox from "../popups/MessageBox";
import Spinner from "../Spinner";
import CreateFormData from "../../utils/CreateFormData";

const ProjectForm = ({ action, project }) => {
  const navigate = useNavigate();

  const [title, setTitle] = useState(project?.title);
  const [campaignUrl, setCampaignUrl] = useState(project?.campaign_url);
  const [videoUrl, setVideoUrl] = useState(project?.video_url);
  const [presentation, setPresentation] = useState(project?.presentation);

  const [files, setFiles] = useState([]);
  const [imagesToDeleteArray, setImagesToDeleteArray] = useState([]);

  const [validationErrors, setValidationErrors] = useState([]);

  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const uploadImages = async (projectId) => {
    try {
      if (projectId) {
        const data = await CreateFormData(files);

        const response = await axios.post(`/api/projects/${projectId}/upload`, data);
        if (response.status === 200) {
          showSuccessBox("Le projet a bien été sauvegardé.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const addProject = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.post("/api/projects", {
        title,
        campaign_url: campaignUrl,
        video_url: videoUrl,
        presentation,
      });

      const projectId = response.data.newProject.id;

      if (files?.length !== 0) {
        await uploadImages(projectId);
      } else {
        if (response.status === 200) {
          showSuccessBox("Le projet a bien été sauvegardé.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const updateProject = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.patch(`/api/projects/${project.id}`, {
        title,
        campaign_url: campaignUrl,
        video_url: videoUrl,
        presentation,
        imagesToDelete: imagesToDeleteArray,
      });

      if (files?.length !== 0) await uploadImages(project.id);
      else {
        if (response.status === 200) {
          showSuccessBox("Le projet a bien été sauvegardé.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = async (err) => {
    console.log(err);
    setIsRequestLoading(false);
    if (err.response.data.errors) {
      setValidationErrors(err.response.data.errors);
    } else {
      showErrorBox("Une erreur est survenue.");
    }
  };

  const showSuccessBox = async (msg) => {
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("success");
    setMessage(msg);
  };

  const showErrorBox = async (msg) => {
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("error");
    setMessage(msg);
  };

  return (
    <>
      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);
            if (messageType === "success") {
              /* redirect to edit page */
              navigate("/edit");
              window.location.reload(true);
            } else if (messageType === "error") {
              window.location.reload(true);
            }
          }}
        />
      )}

      <form className="edit-form">
        {action === "add" && <h3>Nouveau Projet</h3>}

        <div className="inputs-container">
          <label className="required" htmlFor="title">
            Titre
          </label>
          <input
            type="text"
            name="title"
            defaultValue={action === "update" ? project.title : ""}
            placeholder={action === "update" ? project.title : "Entrez un titre"}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />

          <label className="required" htmlFor="presentation">
            Présentation
          </label>
          <textarea
            name="presentation"
            defaultValue={action === "update" ? project.presentation : ""}
            placeholder={action === "update" ? project.presentation : "Présentez le projet"}
            onChange={(e) => {
              setPresentation(e.target.value);
            }}
          />

          <label htmlFor="campaign_url">URL de la campagne</label>
          <input
            type="url"
            name="campaign_url"
            defaultValue={action === "update" ? project.campaign_url : ""}
            placeholder={action === "update" ? project.campaign_url : "Entrez l'URL de la campagne"}
            onChange={(e) => {
              setCampaignUrl(e.target.value);
            }}
          />

          <label htmlFor="video_url">URL de la vidéo</label>
          <input
            type="url"
            name="video_url"
            defaultValue={action === "update" ? project.video_url : ""}
            placeholder={action === "update" ? project.video_url : "Entrez l'URL d'une vidéo"}
            onChange={(e) => {
              setVideoUrl(e.target.value);
            }}
          />

          <label htmlFor="images">Images</label>
          <div className="thumb-overview">
            {project?.images.map((image, index) => {
              return (
                <div key={index} className="thumb-box">
                  <img
                    alt=""
                    src={image.thumb_url}
                    className={imagesToDeleteArray.includes(image) ? "image-to-delete" : ""}
                  />
                  <button
                    className="delete-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      if (imagesToDeleteArray.includes(image)) {
                        setImagesToDeleteArray(imagesToDeleteArray.filter((item) => item.id !== image.id));
                      } else {
                        setImagesToDeleteArray((imagesToDeleteArray) => [...imagesToDeleteArray, image]);
                      }
                    }}
                  >
                    {imagesToDeleteArray.includes(image) ? (
                      <FontAwesomeIcon icon={faTrashArrowUp} />
                    ) : (
                      <FontAwesomeIcon icon={faTrash} />
                    )}
                  </button>
                </div>
              );
            })}
          </div>

          <p>
            <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> Les fichiers doivent être inférieurs à 5MB
            et au format ".jpg".
          </p>

          <input
            type="file"
            name="images"
            multiple
            accept=".jpg, .jpeg"
            onChange={(e) => {
              setFiles(e.target.files);
            }}
          />
        </div>

        {validationErrors.length !== 0 && (
          <div className="validation-errors">
            {validationErrors.map((error, index) => (
              <p key={index} className="wrong-message">
                {error.message}
              </p>
            ))}
          </div>
        )}

        {action === "add" && (
          <button value="ajouter" className="add-btn" onClick={addProject}>
            {isRequestLoading ? <Spinner size="small" /> : "ajouter"}
          </button>
        )}
      </form>

      {action === "update" && (
        <button value="update" className="send-btn white-btn" onClick={updateProject}>
          {isRequestLoading ? <Spinner size="small" /> : "sauvegarder"}
        </button>
      )}
    </>
  );
};

export default ProjectForm;
