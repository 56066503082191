import React from "react";
import Carousel from "nuka-carousel";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const SlideshowTestimonials = ({ slides }) => {
  if (slides)
    return (
      <div className="slideshow slideshow-testimonial">
        <Carousel
          adaptiveHeight={true}
          enableKeyboardControls={true}
          defaultControlsConfig={{
            nextButtonText: ">",
            prevButtonText: "<",
          }}
          renderCenterLeftControls={({ previousSlide, currentSlide }) => (
            <button className={currentSlide === 0 ? "inactive light-btn" : "light-btn"} onClick={previousSlide}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
          )}
          renderCenterRightControls={({ nextSlide, currentSlide }) => (
            <button
              className={currentSlide === slides.length - 1 ? "inactive light-btn" : "light-btn"}
              onClick={nextSlide}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          )}
        >
          {slides?.map((testimonial, index) => {
            return (
              <div className="slide" key={index}>
                {testimonial.image_url && (
                  <span className="circle-image">
                    <img alt="" src={testimonial.image_url} />
                  </span>
                )}
                <p>{testimonial.body}</p>
                <p className="testimonial-author">{testimonial.description}</p>
                {testimonial.video_url && <ReactPlayer className="react-player" url={testimonial.video_url} controls />}
              </div>
            );
          })}
        </Carousel>
      </div>
    );
};

export default SlideshowTestimonials;
