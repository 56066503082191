import React from "react";

const About = () => {
  return (
    <section className="about section">
      <h2>Qui sommes nous ?</h2>
      <p>
        La Fédération Française Sports pour Tous (SPT) est une <b>fédération nationale</b> reconnue d’utilité publique
        par le Ministère des Sports et soutenue par le mouvement olympique.
      </p>
      <p>
        Elle est représentée dans chaque département et chaque grande région (le Comité SPT 49 est rattaché au Comité
        SPT des Pays de Loire).
      </p>
      <p>
        <b>Chaque département doit assurer la promotion du Sports pour Tous</b>, à savoir la diffusion de toute activité
        physique et sportive de loisirs ouverte à tous les publics. Elle est susceptible de répondre à la demande des
        publics sédentaires, des publics atteints de pathologies diverses (et notamment les pathologies chroniques et
        les patients déclarés en Affection Longue Durée, en constante croissance depuis de longues années).{" "}
        <b>Toutes les tranches d’âge</b> sont concernées (enfants à partir de 3 ans, adolescents, adultes incluant le
        4ème âge). Les offres d’activités s’adressent bien évidemment aux personnes en situation de handicap (moteur,
        sensoriel, ou même psychique)
      </p>
    </section>
  );
};

export default About;
