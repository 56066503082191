import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import axios from "axios";
import Slideshow from "../components/Slideshow";
import SlideshowTestimonials from "../components/SlideshowTestimonials";
import Spinner from "../components/Spinner";
import CompensationsTable from "../components/tables/CompensationsTable";
import LevelsTable from "../components/tables/LevelsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faDownload, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import Pdf from "../components/Pdf";

const ProjectPage = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [project, setProject] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    setIsLoading(true);

    const fetchProject = async () => {
      try {
        const { data } = await axios.get(`/api/projects/${id}`);
        setProject(data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(true);
      }
    };

    fetchProject();
  }, [id]);

  console.log(project);

  return (
    <div className="project page">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <section className="project-title">
            <h2 className="pagetitle">{project.title}</h2>

            <button
              className="  send-btn back"
              onClick={(e) => {
                navigate("/");
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          </section>
          {project.images?.length !== 0 && <Slideshow slides={project.images} />}

          <section className="project-presentation">
            <h3>Présentation</h3>
            <p>{project.presentation}</p>

            {project.video_url && <ReactPlayer className="react-player" url={project.video_url} controls />}

            <div>
              <PDFDownloadLink
                className="send-btn nav-link"
                document={<Pdf project={project} key={project.id} />}
                fileName={project.title?.replace(/\s/g, "_") + ".pdf"}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    "Pdf en attente..."
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faDownload} /> pdf
                    </>
                  )
                }
              </PDFDownloadLink>

              <a href={"mailto:" + project.contact_mail} target="_blank" rel="noreferrer" className="send-btn nav-link">
                <FontAwesomeIcon icon={faEnvelope} /> contact
              </a>
            </div>

            {(project.stake?.length === 0 || project.stake === null) && <hr />}
          </section>

          {project.stakes?.length !== 0 && project.stakes !== null && (
            <section className="project-stake">
              <h3>Les enjeux</h3>
              {project.stakes?.map((stake, index) => {
                return (
                  <div key={index} className="stake-container">
                    <p>{stake.body}</p>
                    {stake.image_url && <img alt="" src={stake.image_url} />}
                    {stake.video_url && <ReactPlayer className="react-player" url={stake.video_url} controls />}
                  </div>
                );
              })}
            </section>
          )}

          {project.testimonials?.length !== 0 && (
            <section className="project-testimonials">
              <h3>Témoignages</h3>
              <SlideshowTestimonials slides={project.testimonials} />
            </section>
          )}

          {project.progressions?.length !== 0 && project.progressions !== null && (
            <section className="project-progression">
              <h3>Où en est le projet ?</h3>
              {project.progressions?.map((progression, index) => {
                const progressionDate = new Date(progression.date);
                return (
                  <div key={index} className="progression">
                    <h4>{progressionDate.toLocaleDateString("fr-FR")}</h4>
                    <p>{progression.body}</p>
                  </div>
                );
              })}
              <hr />
            </section>
          )}

          <section className="project-compensations">
            <h3>Mécénat</h3>
            <p>
              Chaque fois que vous versez un don sur ce projet, vous recevez un reçu fiscal pour votre future
              déclaration d’impôts. Les tableaux ci-dessous vous donnent un aperçu du coût réel de votre don, et des
              contreparties répondant aux enjeux de votre entreprise, ou votre association.
            </p>

            <hr className="small-hr" />

            <LevelsTable />
            <hr className="small-hr" />

            <CompensationsTable />
          </section>

          <a href={project.campaign_url} className="add-btn nav-link" target="_blank" rel="noreferrer">
            je soutiens ce projet
          </a>
        </>
      )}
    </div>
  );
};

export default ProjectPage;
