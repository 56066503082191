import axios from "axios";
import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { Link } from "react-router-dom";
import Spinner from "./Spinner";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const fetchProjects = async () => {
      try {
        const { data } = await axios.get("/api/projects");
        if (Array.isArray(data)) {
          setProjects(data);
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        setIsLoading(true);
      }
    };

    fetchProjects();
  }, []);

  return (
    <section className="projects section" id="projects">
      <h2 className="pagetitle">Nos Projets en Cours</h2>
      <sub className="pagetitle subtitle">Les grands chantiers...</sub>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {projects.length === 0 ? (
            <p>Aucun projet trouvé.</p>
          ) : (
            <Masonry
              className="masonry-grid"
              columnClassName="masonry-grid-column"
              breakpointCols={{ default: 3, 1100: 2, 700: 1 }}
            >
              {Array.isArray(projects) &&
                projects?.map((project, index) => {
                  return (
                    <div className="card" key={index}>
                      <img src={project.images[0]?.image_url} alt="" />

                      <div className="card-content">
                        <Link to={"/projects/" + project.id}>
                          <h4>{project.title}</h4>
                        </Link>
                        <p>{project.presentation.substring(0, 500)}...</p>
                        <Link to={"/projects/" + project.id} className="blue-link">
                          En savoir plus
                        </Link>
                        <a href={project.campaign_url} className="add-btn nav-link" target="_blank" rel="noreferrer">
                          je soutiens ce projet
                        </a>
                      </div>
                    </div>
                  );
                })}
            </Masonry>
          )}
        </>
      )}
      <hr />
    </section>
  );
};

export default Projects;
