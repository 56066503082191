import axios from "axios";
import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import Spinner from "../components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";

const CommiteePage = () => {
  const [members, setMembers] = useState([]);
  const [isMemberLoading, setIsMemberLoading] = useState(false);

  const [partners, setPartners] = useState([]);
  const [isPartnerLoading, setIsPartnerLoading] = useState(false);

  useEffect(() => {
    setIsMemberLoading(true);
    setIsPartnerLoading(true);

    const fetchMembers = async () => {
      try {
        const { data } = await axios.get("/api/members");
        if (Array.isArray(data)) {
          setMembers(data);
          setIsMemberLoading(false);
        }
      } catch (err) {
        console.log(err);
        setIsMemberLoading(true);
      }
    };

    const fetchPartners = async () => {
      try {
        const { data } = await axios.get("/api/partners");
        if (Array.isArray(data)) {
          setPartners(data);
          setIsPartnerLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchMembers();
    fetchPartners();
  }, []);

  return (
    <div className="commitee page">
      <h2 className="pagetitle">Le Comité 49</h2>

      <div className="commitee-presentation">
        <p>
          <b>L’action du Comité SPT 49 repose sur les règles suivantes :</b>
        </p>
        <ol>
          <li>
            Offre d’une expertise dans la qualité des séances proposées, et leur adaptation au profil de l’adhérent
            pathologique ou non.
          </li>
          <li>
            Qui s’appuie avant tout sur les clubs affiliés à la Fédération Française, et qui s’impliquent dans la durée.
          </li>
          <li>
            Impliquant des groupes de pratique de taille limitée dans les parcours Sport-Santé (8 à 12), et de taille
            adaptée en club, facteur indispensable au contrôle gestuel personnalisé.
          </li>
          <li>
            Présence dans tous les points du département, en privilégiant les zones carencées (territoires éloignés,
            absence d’infrastructure sportive, ou défaut de qualification des animateurs sportifs : à ce sujet le comité
            soutient la formation des animateurs grâce au programme régional de formation et une enveloppe de 1500 €
            (500€ x 3), destinée aux clubs demandeurs.
          </li>
          <li>Prêt de matériel adapté pour initier des nouveaux cours au sein du club.</li>
          <li>Réseau d’animateurs formés disposant d’un savoir-faire "AP Prévention Santé".</li>
          <li>
            Appui sur des partenaires locaux favorables au développement local : IFEPSA, CHU d’Angers, Ville d’Angers,
            REMMEDIA 49.
          </li>
        </ol>

        <p>
          Ainsi sont réunies les conditions de l’accès ou du maintien au bien-être de tous : physique, mental, social,
          permettant d’assurer une cohésion sociale optimale. Et c’est pourquoi nous souhaitons développer également et
          au maximum notre action au sein des entreprises. Des évaluations sont prévues afin de préserver les relations
          privilégiées avec les organismes financeurs (Agence Nationale du Sport, Conseil Régional, Conseil
          départemental, Fondation de France, etc).
        </p>
        <p>
          <b>
            Toute l’équipe du CD Sports pour Tous est à vos côtés pour garantir un développement de club réussi !
            Contactez-nous !
          </b>
        </p>
      </div>
      <hr />

      <section className="members section">
        <h3>Les membres</h3>
        <div className="members-container">
          {isMemberLoading ? (
            <Spinner />
          ) : (
            <Masonry
              className="masonry-grid"
              columnClassName="masonry-grid-column"
              breakpointCols={{ default: 3, 900: 2, 500: 1 }}
            >
              {members?.map((member, index) => {
                return (
                  <div className="card" key={index}>
                    <img src={member.photo_url} alt="" />
                    <div className="card-content">
                      <h4>{member.first_name + " " + member.last_name.toUpperCase()}</h4>

                      <h5>{member.job && member.job}</h5>
                      <h5>{member.member_type && member.member_type}</h5>
                      <p>{member.presentation}</p>

                      {member.is_tech_team ? (
                        <FontAwesomeIcon
                          icon={faScrewdriverWrench}
                          alt="symbole membre équipe technique"
                          title="Équipe Technique Départementale"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
            </Masonry>
          )}
        </div>
      </section>

      <hr />

      <section className="partners section">
        <h3>Nos partenaires donateurs</h3>
        <div className="partners-container">
          {isPartnerLoading ? (
            <Spinner />
          ) : (
            <>
              {partners
                ?.filter((partner) => partner.is_donor)
                .map((partner, index) => {
                  return (
                    <a key={index} className="partner" target="_blank" rel="noreferrer" href={partner.website_url}>
                      <img
                        className="partner-logo"
                        src={partner.image_url ? partner.image_url : "/img/defaultLogo.png"}
                        alt=""
                      />
                    </a>
                  );
                })}
            </>
          )}
        </div>

        <hr />

        <h3>Autres partenaires</h3>
        <div className="partners-container">
          {isPartnerLoading ? (
            <Spinner />
          ) : (
            <>
              {partners
                ?.filter((partner) => !partner.is_donor)
                .map((partner, index) => {
                  return (
                    <a key={index} className="partner" target="_blank" rel="noreferrer" href={partner.website_url}>
                      <img
                        className="partner-logo"
                        src={partner.image_url ? partner.image_url : "/img/defaultLogo.png"}
                        alt=""
                      />
                    </a>
                  );
                })}
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default CommiteePage;
