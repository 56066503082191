import React, { useEffect, useState } from "react";
import axios from "axios";
import MessageBox from "../components/popups/MessageBox";

const ContactPage = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  const [validationErrors, setValidationErrors] = useState([]);
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const sendMessage = async () => {
    try {
      const message = {
        email,
        name,
        subject,
        body,
      };

      const response = await axios.post("/contact", message);
      if (response.status === 200) {
        setMessageBoxVisible(true);
        setMessageType("success");
        setMessage("Merci ! Votre message a bien été envoyé.");
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.errors) setValidationErrors(err.response.data.errors);
      else {
        setMessageBoxVisible(true);
        setMessageType("error");
        setMessage("Une erreur est survenue.");
      }
    }
  };

  return (
    <div className="contact page">
      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);
            window.location.reload(true);
          }}
        />
      )}

      <h2 className="pagetitle">Contact</h2>

      <p>
        Si vous souhaitez contacter le Comité, c'est le bon endroit ! <br />
        Un problème lié au site internet ? Merci de contacter le développeur{" "}
        <a
          href="mailto:margaleon@tutanota.com?subject=Site CSPT | Question technique"
          target="_blank"
          rel="noreferrer"
          className="blue-link"
        >
          ici
        </a>
        .
      </p>

      <form method="post">
        <div className="form-element">
          <label className="required" htmlFor="email">
            Email
          </label>
          <input
            name="email"
            type="email"
            placeholder="Votre email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>

        <div className="form-element">
          <label className="required" htmlFor="name">
            Nom
          </label>
          <input
            name="name"
            type="text"
            placeholder="Votre nom"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>

        <div className="form-element">
          <label className="required" htmlFor="subject">
            Objet
          </label>
          <input
            name="subject"
            type="text"
            placeholder="Objet du message"
            onChange={(e) => {
              setSubject(e.target.value);
            }}
          />
        </div>

        <div className="form-element">
          <label className="required" htmlFor="body">
            Message
          </label>
          <textarea
            name="body"
            type="text"
            placeholder="Écrivez votre message ici !"
            onChange={(e) => {
              setBody(e.target.value);
            }}
          />
        </div>
      </form>

      {validationErrors.length !== 0 && (
        <div className="validation-errors">
          {validationErrors.map((error, index) => (
            <p key={index} className="wrong-message">
              {error.message}
            </p>
          ))}
        </div>
      )}

      <button className="  send-btn" type="submit" value="submit" onClick={sendMessage}>
        envoyer
      </button>
    </div>
  );
};

export default ContactPage;
