import React, { useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Navigate } from "react-router-dom";
import UserForm from "../components/forms/UserForm";
import Spinner from "../components/Spinner";

const ProfilePage = ({ loggedIn }) => {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    setIsLoading(true);

    const fetchProfile = async () => {
      try {
        const { data } = await axios.get("/api/users/profile");
        setUser(data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchProfile();
  }, []);

  if (!loggedIn) {
    return <Navigate to="/login" replace />;
  } else
    return (
      <div className="profile page">
        <h2 className="pagetitle">Informations du compte</h2>
        <hr />
        {isLoading ? <Spinner /> : <UserForm user={user} />}
      </div>
    );
};

export default ProfilePage;
