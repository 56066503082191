import React from "react";

const ConfirmBox = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="shadow-bg">
      <div className="confirm-box modal">
        <h3>{message}</h3>
        <div className="buttons-container">
          <button onClick={onConfirm} className="add-btn">
            confirmer
          </button>
          <button onClick={onCancel} className="delete-btn">
            annuler
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmBox;
