import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-css";
import VideoPlayer from "./popups/VideoPlayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlay } from "@fortawesome/free-solid-svg-icons";
import Spinner from "./Spinner";
import PdfButton from "./PdfButton";

const Activities = () => {
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedActivity, setSelectedActivity] = useState("");
  const [videoPlayerVisible, setVideoPlayerVisible] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const fetchActivities = async () => {
      try {
        const { data } = await axios.get("/api/activities");
        if (Array.isArray(data)) {
          setActivities(data);
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        setIsLoading(true);
      }
    };

    fetchActivities();
  }, []);

  return (
    <section className="activities section">
      <h2 className="pagetitle">Nos Activités</h2>
      <sub className="pagetitle subtitle">Tous les sports au menu !</sub>

      {isLoading ? (
        <Spinner />
      ) : (
        <Masonry
          className="masonry-grid"
          columnClassName="masonry-grid-column"
          breakpointCols={{ default: 4, 1300: 3, 1000: 2, 750: 1 }}
        >
          {Array.isArray(activities) &&
            activities?.map((activity, index) => {
              return (
                <div className="card" key={index}>
                  <img src={activity.thumb_url} alt="" />
                  <div className="card-content">
                    <h4>{activity.name}</h4>
                    <p>{activity.description}</p>

                    {/*{activity.clubs?.length !== 0 && (
                      <>
                        <hr />
                        <h5>Les clubs proposant cette activité :</h5>
                        <ul>
                          {activity.clubs.map((club, index) => {
                            return <li key={index}>{club.name}</li>;
                          })}
                        </ul>
                      </>
                    )} */}

                    {activity.video_url && (
                      <button
                        className="send-btn"
                        onClick={(e) => {
                          setSelectedActivity(activity);
                          setVideoPlayerVisible(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlay} /> voir la vidéo
                      </button>
                    )}

                    {activity.flyer_url && activity.flyer_url.length !== undefined && (
                      <>
                        <hr />
                        <PdfButton doc_url={activity.flyer_url} innerText="voir le document" />
                      </>
                    )}
                  </div>

                  {videoPlayerVisible && (
                    <VideoPlayer object={selectedActivity} onClose={(e) => setVideoPlayerVisible(false)} />
                  )}
                </div>
              );
            })}
        </Masonry>
      )}

      <p>
        Pour en savoir plus sur les clubs, c'est{" "}
        <Link to="/clubs" className="blue-link">
          ici
        </Link>
        .
      </p>
    </section>
  );
};

export default Activities;
