import React, { useEffect } from "react";
import About from "../components/About";
import Activities from "../components/Activities";
import Projects from "../components/Projects";
import Slideshow from "../components/Slideshow";

const homeSlides = [
  {
    image_url: "/img/home/1.jpg",
  },
  {
    image_url: "/img/home/2.jpg",
  },
  {
    image_url: "/img/home/3.jpg",
  },
  {
    image_url: "/img/home/4.jpg",
  },
];

const Home = () => {
  return (
    <div className="home">
      <Slideshow slides={homeSlides} />
      <About />
      <Projects id="projects" />
      <Activities id="activities" />
    </div>
  );
};

export default Home;
