import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../Spinner";

const MessageBox = ({ message, type, onClose, onTimeout }) => {
  useEffect(() => {
    if (onTimeout) setTimeout(() => onTimeout(), 3000);
  }, [onTimeout]);

  return (
    <div className="shadow-bg">
      <div className="msg-box modal">
        {onTimeout && (
          <div className="centered-box">
            {type && <h3 className={type}>{type === "success" ? "Succès !" : "Erreur !"}</h3>}
            <h3>{message ? message : "En attente..."}</h3>
            <p>Vous allez être redirigé.</p>
            <Spinner />
          </div>
        )}

        {onClose && (
          <>
            <h3 className={type}>{type === "success" ? "Succès !" : "Erreur !"}</h3>
            <h3>{message}</h3>
            <button onClick={onClose} className="delete-btn xmark">
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default MessageBox;
