import axios from "axios";
import React, { useState, useEffect } from "react";
import VideoPlayer from "../components/popups/VideoPlayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlay, faThumbTack } from "@fortawesome/free-solid-svg-icons";
import PdfButton from "../components/PdfButton";
import Slideshow from "../components/Slideshow";
import Spinner from "../components/Spinner";

const EventsPage = () => {
  const [pinnedEvents, setPinnedEvents] = useState([]);
  const [unpinnedEvents, setUnpinnedEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState("");
  const [videoPlayerVisible, setVideoPlayerVisible] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const fetchEvents = async () => {
      try {
        const { data } = await axios.get("/api/events");
        if (Array.isArray(data)) {
          const pinned = data.filter((event) => event.pinned);
          const unpinned = data.filter((event) => !event.pinned);

          setPinnedEvents(pinned);
          setUnpinnedEvents(unpinned);

          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchEvents();
  }, []);

  return (
    <div className="events page">
      <h2 className="pagetitle">Actualités</h2>
      <hr />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {pinnedEvents.length === 0 && unpinnedEvents.length === 0 ? (
            <p>Aucune actualité pour le moment.</p>
          ) : (
            <>
              {pinnedEvents.map((event, index) => {
                const eventDate = new Date(event.date);

                return (
                  <div key={index} className="events-container">
                    {event.pinned ? (
                      <div className="pin-container">
                        <FontAwesomeIcon icon={faThumbTack} className="pin" />
                      </div>
                    ) : (
                      ""
                    )}

                    <h3>{event.title}</h3>
                    <h4>{eventDate.toLocaleDateString("fr-FR")}</h4>
                    <p>{event.body}</p>

                    {event.doc_url && <PdfButton doc_url={event.doc_url} innerText="voir le document" />}

                    {event.video_url && (
                      <div className="doc-btn-container">
                        <button
                          className="send-btn doc-btn"
                          onClick={(e) => {
                            setSelectedEvent(event);
                            setVideoPlayerVisible(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faPlay} /> voir la vidéo
                        </button>
                      </div>
                    )}

                    {event.images?.length !== 0 && <Slideshow slides={event.images} />}

                    {event.article_url && (
                      <>
                        <hr />
                        <h5>Le lien vers l'article :</h5>
                        <a href={event.article_url} className="blue-link" target="_blank" rel="noreferrer">
                          {event.article_url}
                        </a>
                      </>
                    )}

                    {event.program_url && (
                      <>
                        <hr />
                        <h5>Le lien vers l'émission :</h5>
                        <a href={event.program_url} className="blue-link" target="_blank" rel="noreferrer">
                          {event.program_url}
                        </a>
                      </>
                    )}
                  </div>
                );
              })}

              {unpinnedEvents.map((event, index) => {
                const eventDate = new Date(event.date);

                return (
                  <div key={index} className="events-container">
                    {event.pinned ? (
                      <div className="pin-container">
                        <FontAwesomeIcon icon={faThumbTack} className="pin" />
                      </div>
                    ) : (
                      ""
                    )}

                    <h3>{event.title}</h3>
                    <h4>{eventDate.toLocaleDateString("fr-FR")}</h4>
                    <p>{event.body}</p>

                    {event.doc_url && <PdfButton doc_url={event.doc_url} innerText="voir le document" />}

                    {event.video_url && (
                      <div className="doc-btn-container">
                        <button
                          className="send-btn doc-btn"
                          onClick={(e) => {
                            setSelectedEvent(event);
                            setVideoPlayerVisible(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faPlay} /> voir la vidéo
                        </button>
                      </div>
                    )}

                    {event.images?.length !== 0 && <Slideshow slides={event.images} />}

                    {event.article_url && (
                      <>
                        <hr />
                        <h5>Le lien vers l'article :</h5>
                        <a href={event.article_url} className="blue-link" target="_blank" rel="noreferrer">
                          {event.article_url}
                        </a>
                      </>
                    )}

                    {event.program_url && (
                      <>
                        <hr />
                        <h5>Le lien vers l'émission :</h5>
                        <a href={event.program_url} className="blue-link" target="_blank" rel="noreferrer">
                          {event.program_url}
                        </a>
                      </>
                    )}
                  </div>
                );
              })}

              {videoPlayerVisible && (
                <VideoPlayer object={selectedEvent} onClose={(e) => setVideoPlayerVisible(false)} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default EventsPage;
