import React, { useState, useEffect } from "react";
import { TableRow, SingleTable } from "./SingleTable";

const LevelsTable = () => {
  const [isScreenSmall, setIsScreenSmall] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 750px)");
    mediaQuery.addEventListener("change", (mediaQuery) => {
      if (mediaQuery.matches) {
        setIsScreenSmall(true);
      } else {
        setIsScreenSmall(false);
      }
    });
    if (mediaQuery.matches) {
      setIsScreenSmall(true);
    } else {
      setIsScreenSmall(false);
    }
  }, []);

  return (
    <>
      <h4>Les Paliers de Don</h4>

      {isScreenSmall ? (
        <>
          <SingleTable type="levels" title="Don de Base" values={[20, 8, "", ""]} />
          <SingleTable type="levels" title="Médaille de Bronze" values={[100, 40, "", ""]} />
          <SingleTable type="levels" title="Médaille d'Argent" values={[300, 120, "", ""]} />
          <SingleTable type="levels" title="Médaille d'Or" values={[1000, 400, 250, 350]} />
        </>
      ) : (
        <table className="levels-table">
          <tbody>
            <tr className="cols-headers">
              <th></th>

              <th>Don de Base</th>
              <th className="th-img">
                <img alt="" className="medal" src="/img/medals/bronze.png" />
                <p>Médaille de Bronze</p>
              </th>
              <th className="th-img">
                <img alt="" className="medal" src="/img/medals/silver.png" />
                <p>Médaille d'Argent</p>
              </th>
              <th className="th-img">
                <img alt="" className="medal" src="/img/medals/gold.png" />
                <p>Médaille d'Or</p>
              </th>
            </tr>
          </tbody>

          <TableRow title="Minimum *" values={[20, 100, 300, 1000]} />
          <TableRow title="Après déduction fiscale" values={[8, 40, 120, 400]} />
          <TableRow title="Valeur contreparties" values={["", "", "", 250]} />
          <TableRow title="Coût réel" values={["", "", "", 350]} />
        </table>
      )}
      <p>* Valeur inscrite sur le reçu fiscal</p>
    </>
  );
};

export default LevelsTable;
