import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashArrowUp, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import MessageBox from "../popups/MessageBox";
import Spinner from "../Spinner";
import CreateFormData from "../../utils/CreateFormData";

const MemberForm = ({ member, action }) => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState(member?.first_name);
  const [lastName, setLastName] = useState(member?.last_name);
  const [email, setEmail] = useState(member?.email);
  const [isTechTeam, setIsTechTeam] = useState(member?.is_tech_team);
  const [job, setJob] = useState(member?.job);
  const [presentation, setPresentation] = useState(member?.presentation);
  const [memberType, setMemberType] = useState(member?.member_type);
  const [photoUrl, setPhotoUrl] = useState(member?.photo_url);

  const [files, setFiles] = useState("");
  const [deleteImage, setDeleteImage] = useState(false);

  const [validationErrors, setValidationErrors] = useState([]);
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const uploadImages = async (memberId) => {
    try {
      if (memberId) {
        const data = await CreateFormData(files);

        const response = await axios.post(`/api/members/${memberId}/upload`, data);
        if (response.status === 200) {
          showSuccessBox("Le membre a bien été sauvegardé.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const addMember = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.post("/api/members", {
        first_name: firstName,
        last_name: lastName,
        email,
        is_tech_team: isTechTeam,
        job,
        presentation,
        member_type: memberType,
      });
      const memberId = response.data.newMember.id;

      if (files?.length !== 0) {
        await uploadImages(memberId);
      } else {
        if (response.status === 200) {
          showSuccessBox("Le membre a bien été sauvegardé.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const updateMember = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.patch(`/api/members/${member.id}`, {
        first_name: firstName,
        last_name: lastName,
        email,
        is_tech_team: isTechTeam,
        job,
        presentation,
        member_type: memberType,
      });

      if (files?.length !== 0) {
        await uploadImages(member.id);
      } else {
        if (response.status === 200) {
          showSuccessBox("Le membre a bien été sauvegardé.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = async (err) => {
    setIsRequestLoading(false);
    console.log(err);
    if (err.response.data.errors) setValidationErrors(err.response.data.errors);
    else {
      navigate("/internal-error");
      showErrorBox("Une erreur est survenue.");
    }
  };

  const showSuccessBox = async (msg) => {
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("success");
    setMessage(msg);
  };

  const showErrorBox = async (msg) => {
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("error");
    setMessage(msg);
  };

  return (
    <>
      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);

            if (messageType === "success") {
              /* redirect to commitee page */
              navigate("/commitee");
              window.location.reload(true);
            } else if (messageType === "error") {
              window.location.reload(true);
            }
          }}
        />
      )}

      <form className="edit-form">
        {action === "add" && <h3>Nouveau Membre</h3>}

        <div className="inputs-container">
          <label className="required" htmlFor="first_name">
            Prénom
          </label>
          <input
            type="text"
            name="first_name"
            defaultValue={action === "update" ? member.first_name : ""}
            placeholder={action === "update" ? member.first_name : "Entrez le prénom du membre"}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />

          <label className="required" htmlFor="last_name">
            Nom
          </label>
          <input
            type="text"
            name="last_name"
            defaultValue={action === "update" ? member.last_name : ""}
            placeholder={action === "update" ? member.last_name : "Entrez le nom du membre"}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />

          <label htmlFor="email">Mail</label>
          <input
            type="text"
            name="email"
            defaultValue={action === "update" ? member.email : ""}
            placeholder={action === "update" ? member.email : "Entrez le mail du membre"}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

          <label htmlFor="job">Poste</label>
          <input
            type="text"
            name="job"
            defaultValue={action === "update" ? member.job : ""}
            placeholder={action === "update" ? member.job : "Entrez l'intitulé du poste du membre"}
            onChange={(e) => {
              setJob(e.target.value);
            }}
          />

          <label htmlFor="member_type">Type de Membre</label>
          <input
            type="text"
            name="member_type"
            defaultValue={action === "update" ? member.member_type : ""}
            placeholder={action === "update" ? member.member_type : "Membre élu/élue"}
            onChange={(e) => {
              setMemberType(e.target.value.toUpperCase());
            }}
          />

          <div className="form-row check">
            <label htmlFor="is_tech_team">Membre de l'équipe technique ?</label>
            <input
              type="checkbox"
              name="is_tech_team"
              defaultChecked={action === "update" ? member.is_tech_team && true : false}
              onChange={(e) => {
                if (e.target.checked) {
                  setIsTechTeam(1);
                } else setIsTechTeam(0);
              }}
            />
          </div>

          <label htmlFor="presentation">Présentation</label>
          <textarea
            type="text"
            name="presentation"
            defaultValue={action === "update" ? member.presentation : ""}
            placeholder={action === "update" ? member.presentation : "Entrez une présentation du membre"}
            onChange={(e) => {
              setPresentation(e.target.value);
            }}
          />

          <label htmlFor="images">Photo</label>
          <div className="thumb-overview">
            {member?.photo_url && (
              <div className="thumb-box">
                <img alt="" src={member.photo_url} className={deleteImage ? "image-to-delete" : ""} />
                <button
                  className="delete-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!deleteImage) {
                      setDeleteImage(true);
                    } else {
                      setDeleteImage(false);
                    }
                    console.log(deleteImage);
                  }}
                >
                  {deleteImage ? <FontAwesomeIcon icon={faTrashArrowUp} /> : <FontAwesomeIcon icon={faTrash} />}
                </button>
              </div>
            )}
          </div>

          <p>
            <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> Le fichier doit être inférieur à 5MB.
          </p>
          <p>
            <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> Un membre ne peut stocker qu'une seule
            image.
          </p>

          <input
            type="file"
            name="images"
            accept=".jpg, .jpeg, .png"
            onChange={(e) => {
              setFiles(e.target.files);
            }}
          />
        </div>

        {validationErrors.length !== 0 && (
          <div className="validation-errors">
            {validationErrors.map((error, index) => (
              <p key={index} className="wrong-message">
                {error.message}
              </p>
            ))}
          </div>
        )}

        {action === "add" && (
          <button value="ajouter" type="submit" className="add-btn" onClick={addMember}>
            {isRequestLoading ? <Spinner size="small" /> : "ajouter"}
          </button>
        )}
      </form>

      {action === "update" && (
        <button value="update" type="submit" className="send-btn white-btn" onClick={updateMember}>
          {isRequestLoading ? <Spinner size="small" /> : "sauvegarder"}
        </button>
      )}
    </>
  );
};

export default MemberForm;
