import React, { useEffect, useState } from "react";
import axios from "axios";
import Slideshow from "../components/Slideshow";
import Spinner from "../components/Spinner";
import MapClubs from "../components/maps/MapClubs";
import PdfButton from "../components/PdfButton";
import FormatEmail from "../utils/FormatEmail";
import DocumentsList from "../components/popups/DocumentsList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const ClubsPage = () => {
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [detailIndex, setDetailIndex] = useState(null);

  const [isDocsPopupVisible, setIsDocsPopupVisible] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [club, setClub] = useState({});

  const [clubs, setClubs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDetailIndex(null);
    document.getElementById("root").scrollTo(0, 0);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    const fetchClubs = async () => {
      try {
        const { data } = await axios.get("/api/clubs");
        setClubs(data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchClubs();
  }, []);

  const showDetails = async (index) => {
    setDetailIndex(index);
    setIsDetailVisible(true);
  };

  const showDocsList = async (e) => {
    e.preventDefault();
    setDocuments(clubs[detailIndex].documents);
    setClub(clubs[detailIndex]);
    setIsDocsPopupVisible(true);
  };

  return (
    <div className="clubs page">
      <h2 className="pagetitle">Les Clubs</h2>
      <hr />

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {Array.isArray(clubs) && (
            <div className="clubs-map">
              {window.WebGLRenderingContext ? (
                <MapClubs clubs={clubs} clubSelected={detailIndex} />
              ) : (
                <p className="map-error">
                  <FontAwesomeIcon icon={faTriangleExclamation} /> Votre
                  navigateur ne supporte pas WebGL, la carte ne peut pas
                  s'afficher correctement.
                </p>
              )}
            </div>
          )}

          {isDocsPopupVisible && (
            <DocumentsList
              docs={documents}
              club={club}
              onClose={(e) => {
                setIsDocsPopupVisible(false);
                // window.location.reload(true);
              }}
            />
          )}

          {Array.isArray(clubs) &&
            clubs?.map((club, index) => {
              return (
                <div key={index} className="clubs-container" id={club.id}>
                  <div className="club-title">
                    <h3>{club.name}</h3>
                    <button
                      className="  send-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        showDetails(index);
                      }}
                    >
                      +
                    </button>
                  </div>

                  {isDetailVisible && detailIndex === index && (
                    <>
                      <hr />
                      <div className="clubs-details">
                        {(club.hq_address_line_1 ||
                          club.hq_address_line_2 ||
                          club.postal_code ||
                          club.city) && (
                          <div className="details-container">
                            <h5>Adresse du Siège :</h5>
                            <p>
                              {club.hq_address_line_2 && (
                                <>{club.hq_address_line_2 + ", "}</>
                              )}

                              {club.hq_address_line_1 && club.hq_address_line_1}
                              <br />
                              {(club.postal_code &&
                                club.postal_code.toUpperCase()) +
                                " " +
                                (club.city && club.city.toUpperCase())}
                            </p>
                          </div>
                        )}

                        {(club.phone ||
                          club.contact_mail ||
                          club.website_url) && (
                          <div className="details-container">
                            <h5>Contact : </h5>

                            {club.phone && <p>Téléphone : {club.phone}</p>}

                            {club.contact_mail && (
                              <a
                                className="blue-link"
                                href={"mailto:" + club.contact_mail}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {FormatEmail(club.contact_mail)}
                              </a>
                            )}

                            {club.website_url && (
                              <a
                                className="blue-link"
                                href={club.website_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Site Internet
                              </a>
                            )}
                          </div>
                        )}
                      </div>

                      {club.images?.length !== 0 && (
                        <Slideshow slides={club.images} />
                      )}

                      {club.documents?.length !== 0 && (
                        <>
                          <hr />
                          <div className="doc-btn-container">
                            <button
                              className="  send-btn doc-btn"
                              onClick={showDocsList}
                            >
                              voir les documents
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              );
            })}
        </>
      )}
    </div>
  );
};

export default ClubsPage;
