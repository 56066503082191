import axios from "axios";
import React, { useEffect, useState } from "react";
import ProgressionForm from "../forms/ProgressionForm";
import ConfirmBox from "../popups/ConfirmBox";
import MessageBox from "../popups/MessageBox";
import Spinner from "../../components/Spinner";

const ProgressionsList = () => {
  const [progressions, setProgressions] = useState([]);
  const [selectedProgression, setSelectedProgression] = useState("");
  const [isProgressionLoading, setIsProgressionLoading] = useState(false);

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [isProjectLoading, setIsProjectLoading] = useState(false);

  const [action, setAction] = useState("");
  const [updateMode, setUpdateMode] = useState("");

  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    setIsProgressionLoading(true);
    setIsProjectLoading(true);
    setUpdateMode(false);

    const fetchProjects = async () => {
      try {
        const { data } = await axios.get("/api/projects");
        setProjects(data);
        setIsProjectLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    const fetchProgressions = async () => {
      try {
        const { data } = await axios.get("/api/progressions");
        setProgressions(data);
        setIsProgressionLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchProjects();
    fetchProgressions();
  }, []);

  const askConfirmation = async () => {
    setConfirmBoxVisible(true);
  };

  const deleteProgression = async () => {
    try {
      const response = await axios.delete(`/api/progressions/${selectedProgression.id}`);
      if (response.status === 200) {
        setMessageBoxVisible(true);
        setMessageType("success");
        setMessage("Le progrès a bien été supprimé.");
      }
    } catch (err) {
      console.log(err);
      setMessageBoxVisible(true);
      setMessageType("error");
      setMessage("Une erreur est survenue.");
    }
  };

  console.log(progressions);

  return (
    <div className="objects-list">
      {confirmBoxVisible && (
        <ConfirmBox
          message={"Supprimer ce Progrès ?"}
          onConfirm={deleteProgression}
          onCancel={(e) => setConfirmBoxVisible(false)}
        />
      )}

      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);
            window.location.reload(true);
          }}
        />
      )}

      {selectedProject ? (
        <>
          {isProgressionLoading ? (
            <Spinner />
          ) : (
            <>
              {selectedProject.progressions.map((progression, index) => {
                return (
                  <div
                    className={
                      selectedProgression.id === progression.id && updateMode ? "list-item selected" : "list-item"
                    }
                    key={index}
                    value={progression.id}
                  >
                    <h3>{progression.body.substring(0, 50)}...</h3>

                    {selectedProgression.id === progression.id && updateMode ? (
                      <ProgressionForm progression={selectedProgression} project={selectedProject} action="update" />
                    ) : (
                      <div className="buttons-container">
                        <button
                          value="update"
                          className="send-btn"
                          onClick={(e) => {
                            setAction(e.target.value);
                            setSelectedProgression(progression);
                            setUpdateMode(true);
                          }}
                        >
                          modifier
                        </button>
                        <button
                          value="delete"
                          className="delete-btn"
                          onClick={(e) => {
                            setAction(e.target.value);
                            setSelectedProgression(progression);
                            askConfirmation();
                          }}
                        >
                          supprimer
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )}

          <ProgressionForm action="add" project={selectedProject} />
        </>
      ) : (
        <>
          {isProjectLoading ? (
            <Spinner />
          ) : (
            <>
              {projects.map((project, index) => {
                return (
                  <div key={index} className="list-item" value={project.id}>
                    <h3>{project.title}</h3>

                    <button
                      value="update"
                      className="send-btn"
                      onClick={(e) => {
                        setAction(e.target.value);
                        setSelectedProject(project);
                      }}
                    >
                      choisir
                    </button>
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ProgressionsList;
