import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCircleInfo, faClipboardUser, faChampagneGlasses } from "@fortawesome/free-solid-svg-icons";
import ConfirmBox from "../popups/ConfirmBox";
import MessageBox from "../popups/MessageBox";
import Spinner from "../Spinner";
import CreateFormData from "../../utils/CreateFormData";

const ActivityForm = ({ activity, action }) => {
  const [name, setName] = useState(activity?.name);
  const [description, setDescription] = useState(activity?.description);
  const [videoUrl, setVideoUrl] = useState(activity?.video_url);
  const [docUrl, setDocUrl] = useState(activity?.doc_url);
  const [clubsArray, setClubsArray] = useState(activity?.clubs || []);

  const [files, setFiles] = useState("");
  const [imageToDelete, setImageToDelete] = useState("");

  const [clubs, setClubs] = useState([]);
  const [isClubLoading, setIsClubLoading] = useState(false);

  const [validationErrors, setValidationErrors] = useState([]);
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");

  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  useEffect(() => {
    setIsClubLoading(true);

    const fetchClubs = async () => {
      try {
        const { data } = await axios.get("/api/clubs");
        setClubs(data);
        setIsClubLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchClubs();
  }, []);

  const uploadImages = async (activityId) => {
    try {
      if (activityId) {
        const data = await CreateFormData(files);

        const response = await axios.post(`/api/activities/${activity}/upload`, data);
        if (response.status === 200) {
          showSuccessBox("L'activité a bien été sauvegardée.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const addActivity = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.post("/api/activities", {
        name,
        doc_url: docUrl,
        video_url: videoUrl,
        clubs: clubsArray,
      });

      const activityId = response.data.newActivity.id;

      if (files?.length !== 0) {
        await uploadImages(activityId);
      } else {
        if (response.status === 200) {
          showSuccessBox("L'activité a bien été sauvegardée.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const updateActivity = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.patch(`/api/activities/${activity.id}`, {
        name,
        doc_url: docUrl,
        video_url: videoUrl,
        clubs: clubsArray,
      });

      if (files?.length !== 0) {
        await uploadImages(activity.id);
      } else {
        if (response.status === 200) {
          showSuccessBox("L'activité a bien été sauvegardée.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const deleteImage = async (e) => {
    e.preventDefault();
    const response = await axios.delete(`/api/activities/${activity.id}/images/${imageToDelete.id}`);

    if (response.status === 200) {
      showSuccessBox("L'image a bien été supprimée.");
    }
  };

  const handleError = async (err) => {
    console.log(err);
    setIsRequestLoading(false);
    if (err.response.data.errors) {
      setValidationErrors(err.response.data.errors);
    } else {
      showErrorBox("Une erreur est survenue.");
    }
  };

  const showSuccessBox = async (msg) => {
    setConfirmBoxVisible(false);
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("success");
    setMessage(msg);
  };

  const showErrorBox = async (msg) => {
    setConfirmBoxVisible(false);
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("error");
    setMessage(msg);
  };

  return (
    <>
      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);
            window.location.reload(true);
          }}
        />
      )}

      <form className="edit-form">
        {action === "add" && <h3>Nouvelle Activité</h3>}

        <div className="inputs-container">
          <label className="required" htmlFor="name">
            Nom
          </label>
          <input
            type="text"
            name="name"
            defaultValue={action === "update" ? activity.name : ""}
            placeholder={action === "update" ? activity.name : "Entrez le nom de l'activité"}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />

          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            defaultValue={action === "update" ? activity.description : ""}
            placeholder={action === "update" ? activity.description : "Entrez la descprition de l'activité ici"}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />

          <hr />

          <label htmlFor="doc_url">URL d'un document, PDF</label>
          <input
            type="url"
            name="doc_url"
            defaultValue={action === "update" ? activity.doc_url : ""}
            placeholder={action === "update" ? activity.doc_url : "Entrez l'URL du document"}
            onChange={(e) => {
              setDocUrl(e.target.value);
            }}
          />

          <label htmlFor="video_url">URL d'une vidéo</label>
          <input
            type="url"
            name="video_url"
            defaultValue={action === "update" ? activity.video_url : ""}
            placeholder={action === "update" ? activity.video_url : "Entrez l'URL de la vidéo"}
            onChange={(e) => {
              setVideoUrl(e.target.value);
            }}
          />

          <label htmlFor="images">Images</label>
          {confirmBoxVisible && (
            <ConfirmBox
              message={confirmMessage}
              onConfirm={deleteImage}
              onCancel={(e) => setConfirmBoxVisible(false)}
            />
          )}
          <div className="thumb-overview">
            {activity?.images.map((image, index) => {
              return (
                <div key={index} className="thumb-box">
                  <img alt="" src={image.thumb_url} />
                  <button
                    className="delete-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setImageToDelete(image);
                      setConfirmMessage("Supprimer cette Image ?");
                      setConfirmBoxVisible(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              );
            })}
          </div>
          <p>
            <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> Les fichiers doivent être inférieurs à 5MB.
          </p>
          <input
            type="file"
            name="images"
            multiple
            accept=".jpg"
            onChange={(e) => {
              setFiles(e.target.files);
            }}
          />

          <hr />

          <label htmlFor="select_clubs">Clubs</label>
          <p>
            <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> Sélectionnez les clubs qui proposent cette
            activité.
          </p>

          {isClubLoading ? (
            <Spinner />
          ) : (
            <div className="club-buttons">
              {clubs?.map((club, index) => (
                <button
                  key={index}
                  className={clubsArray.find((c) => c.id === club.id) ? "btn-selected" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    e.target.classList.toggle("btn-selected");

                    if (clubsArray.includes(club)) {
                      setClubsArray(clubsArray.filter((item) => item !== club));
                    } else {
                      setClubsArray((clubsArray) => [...clubsArray, club]);
                    }
                  }}
                >
                  {club.name}
                </button>
              ))}
            </div>
          )}
        </div>

        {validationErrors.length !== 0 && (
          <div className="validation-errors">
            {validationErrors.map((error, index) => (
              <p key={index} className="wrong-message">
                {error.message}
              </p>
            ))}
          </div>
        )}

        {action === "add" && (
          <button value="ajouter" type="submit" className="add-btn" onClick={addActivity}>
            {isRequestLoading ? <Spinner size="small" /> : "ajouter"}
          </button>
        )}
      </form>

      {action === "update" && (
        <button value="update" type="submit" className="send-btn white-btn" onClick={updateActivity}>
          {isRequestLoading ? <Spinner size="small" /> : "sauvegarder"}
        </button>
      )}
    </>
  );
};

export default ActivityForm;
