import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EventForm from "../forms/EventForm";
import ConfirmBox from "../popups/ConfirmBox";
import MessageBox from "../popups/MessageBox";
import Spinner from "../../components/Spinner";

const EventsList = () => {
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [action, setAction] = useState("");
  const [updateMode, setUpdateMode] = useState("");

  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    setIsLoading(true);
    setUpdateMode(false);

    const fetchEvents = async () => {
      try {
        const { data } = await axios.get("/api/events");
        setEvents(data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchEvents();
  }, []);

  const askConfirmation = async () => {
    setConfirmBoxVisible(true);
  };

  const deleteEvent = async () => {
    try {
      const response = await axios.delete(`/api/events/${selectedEvent.id}`);
      if (response.status === 200) {
        setMessageBoxVisible(true);
        setMessageBoxVisible(true);
        setMessageType("success");
        setMessage("L'actualité a bien été supprimée.");
      }
    } catch (err) {
      console.log(err);
      setMessageBoxVisible(true);
      setMessageType("error");
      setMessage("Une erreur est survenue.");
    }
  };

  return (
    <div className="objects-list">
      {confirmBoxVisible && (
        <ConfirmBox
          message={"Supprimer cette Actualité ?"}
          onConfirm={deleteEvent}
          onCancel={(e) => setConfirmBoxVisible(false)}
        />
      )}

      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);
            setConfirmBoxVisible(false);
            window.location.reload(true);
          }}
        />
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {events.map((event, index) => {
            return (
              <div
                key={index}
                className={selectedEvent.id === event.id && updateMode ? "list-item selected" : "list-item"}
                value={event.id}
              >
                <h3>{event.title}</h3>

                {selectedEvent.id === event.id && updateMode ? (
                  <EventForm action="update" event={selectedEvent} />
                ) : (
                  <div className="buttons-container">
                    <button
                      value="update"
                      className="send-btn"
                      onClick={(e) => {
                        setAction(e.target.value);
                        setSelectedEvent(event);
                        setUpdateMode(true);
                      }}
                    >
                      modifier
                    </button>

                    <button
                      value="delete"
                      className="delete-btn"
                      onClick={(e) => {
                        setAction(e.target.value);
                        setSelectedEvent(event);
                        askConfirmation();
                      }}
                    >
                      supprimer
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </>
      )}

      <EventForm action="add" />
    </div>
  );
};

export default EventsList;
