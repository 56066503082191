import React from "react";
import FormatEmail from "../../utils/FormatEmail";
import PdfButton from "../PdfButton";

const MapPopup = ({ club, place }) => {
  return (
    <div className="popup-component">
      <h4>{club.name}</h4>
      <hr />

      {(club.hq_address_line_1 ||
        club.hq_address_line_2 ||
        club.postal_code ||
        club.city) && (
        <>
          <h5>Adresse du Siège :</h5>
          <p>
            {club.hq_address_line_2 && <>{club.hq_address_line_2 + ", "}</>}

            {club.hq_address_line_1 && club.hq_address_line_1}
            <br />
            {(club.postal_code && club.postal_code.toUpperCase()) +
              " " +
              (club.city && club.city.toUpperCase())}
          </p>
        </>
      )}

      {place && (
        <>
          <h5>Lieu de Pratique :</h5>
          <p>
            {place.address_line_1 && (
              <>
                {place.address_line_1}
                <br />
              </>
            )}
            {place.address_line_2.toUpperCase()}
          </p>
        </>
      )}

      {club.doc_url && (
        <PdfButton doc_url={club.doc_url} innerText="voir le document" />
      )}
      {(club.phone || club.contact_mail || club.website_url) && (
        <>
          <hr />

          <h5>Contact : </h5>
          {club.phone && <p>Téléphone : {club.phone}</p>}

          {club.contact_mail && (
            <a
              href={"mailto:" + club.contact_mail}
              target="_blank"
              rel="noreferrer"
              className="blue-link"
            >
              {FormatEmail(club.contact_mail)}
            </a>
          )}

          {club.website_url && (
            <a
              href={club.website_url}
              target="_blank"
              rel="noreferrer"
              className="blue-link"
            >
              Site Internet
            </a>
          )}
        </>
      )}
    </div>
  );
};

export default MapPopup;
