import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashArrowUp, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import MessageBox from "../popups/MessageBox";
import Spinner from "../Spinner";
import CreateFormData from "../../utils/CreateFormData";

const StakeForm = ({ stake, project, action }) => {
  const navigate = useNavigate();

  const [body, setBody] = useState(stake?.body);
  const [videoUrl, setVideoUrl] = useState(stake?.video_url);

  const [files, setFiles] = useState("");
  const [deleteImage, setDeleteImage] = useState(false);

  const [validationErrors, setValidationErrors] = useState([]);
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const uploadImages = async (stakeId) => {
    try {
      if (stakeId) {
        const data = await CreateFormData(files);

        const response = await axios.post(`/api/stakes/${stakeId}/upload`, data);
        if (response.status === 200) {
          showSuccessBox("L'enjeu a bien été sauvegardé.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const addStake = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.post("/api/stakes", {
        project_id: project.id,
        body,
        video_url: videoUrl,
      });

      const stakeId = response.data.newStake.id;

      if (files?.length !== 0) {
        await uploadImages(stakeId);
      } else {
        if (response.status === 200) {
          showSuccessBox("L'enjeu a bien été sauvegardé.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const updateStake = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.patch(`/api/stakes/${stake.id}`, {
        body,
        video_url: videoUrl,
        deleteImage,
      });

      if (files?.length !== 0) {
        await uploadImages(stake.id);
      } else {
        if (response.status === 200) {
          showSuccessBox("L'enjeu a bien été sauvegardé.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = async (err) => {
    setIsRequestLoading(false);
    console.log(err);
    if (err.response.data.errors) setValidationErrors(err.response.data.errors);
    else {
      showErrorBox("Une erreur est survenue.");
    }
  };

  const showSuccessBox = async (msg) => {
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("success");
    setMessage(msg);
  };

  const showErrorBox = async (msg) => {
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("error");
    setMessage(msg);
  };

  return (
    <>
      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);
            /* redirect to project page */
            navigate(`/projects/${project.id}`);
            window.location.reload(true);
          }}
        />
      )}

      <form className="edit-form">
        {action === "add" && <h3>Nouvel Enjeu</h3>}

        <div className="inputs-container">
          <label className="required" htmlFor="stake_body">
            Contenu
          </label>
          <textarea
            name="stake_body"
            defaultValue={action === "update" ? stake.body : ""}
            placeholder={action === "update" ? stake.body : "Entrez le contenu de l'enjeu"}
            onChange={(e) => {
              setBody(e.target.value);
            }}
          />

          <label htmlFor="video_url">URL d'une vidéo</label>
          <input
            type="url"
            name="video_url"
            defaultValue={action === "update" ? stake.video_url : ""}
            placeholder={action === "update" ? stake.video_url : "Entrez l'URL de la vidéo"}
            onChange={(e) => {
              setVideoUrl(e.target.value);
            }}
          />

          <label htmlFor="images">Image</label>
          <div className="thumb-overview">
            {stake?.thumb_url && (
              <div className="thumb-box">
                <img alt="" src={stake.thumb_url} className={deleteImage ? "image-to-delete" : ""} />
                <button
                  className="delete-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!deleteImage) {
                      setDeleteImage(true);
                    } else {
                      setDeleteImage(false);
                    }
                    console.log(deleteImage);
                  }}
                >
                  {deleteImage ? <FontAwesomeIcon icon={faTrashArrowUp} /> : <FontAwesomeIcon icon={faTrash} />}
                </button>
              </div>
            )}
          </div>

          <p>
            <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> Le fichier doit être inférieur à 5MB.
          </p>
          <p>
            <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> Un enjeu ne peut stocker qu'une seule image.
          </p>

          <input
            type="file"
            name="images"
            accept=".jpg"
            onChange={(e) => {
              setFiles(e.target.files);
            }}
          />
        </div>

        {validationErrors.length !== 0 && (
          <div className="validation-errors">
            {validationErrors.map((error, index) => (
              <p key={index} className="wrong-message">
                {error.message}
              </p>
            ))}
          </div>
        )}

        {action === "add" && (
          <button value="ajouter" className="add-btn" onClick={addStake}>
            {isRequestLoading ? <Spinner size="small" /> : "ajouter"}
          </button>
        )}
      </form>

      {action === "update" && (
        <button value="update" className="send-btn white-btn" onClick={updateStake}>
          {isRequestLoading ? <Spinner size="small" /> : "sauvegarder"}
        </button>
      )}
    </>
  );
};

export default StakeForm;
