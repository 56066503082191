import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const PdfButton = ({ doc_url, innerText }) => {
  return (
    <div className="doc-btn-container">
      <a href={doc_url} target="_blank" rel="noreferrer" className="nav-link send-btn doc-btn">
        <FontAwesomeIcon icon={faFilePdf} />
        {" " + innerText}
      </a>
    </div>
  );
};

export default PdfButton;
