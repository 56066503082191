import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { TableRow, SingleTable } from "./SingleTable";

const CompensationsTable = () => {
  const [isScreenSmall, setIsScreenSmall] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 750px)");
    mediaQuery.addEventListener("change", (mediaQuery) => {
      if (mediaQuery.matches) {
        setIsScreenSmall(true);
      } else {
        setIsScreenSmall(false);
      }
    });
    if (mediaQuery.matches) {
      setIsScreenSmall(true);
    } else {
      setIsScreenSmall(false);
    }
  }, []);

  return (
    <>
      <h4>Les Contreparties Possibles</h4>

      {isScreenSmall ? (
        <>
          <SingleTable type="compensations" title="Don de Base" values={[true, true, true, false, false, false]} />
          <SingleTable
            type="compensations"
            title="Médaille de Bronze"
            values={[true, true, true, true, false, false]}
          />
          <SingleTable type="compensations" title="Médaille d'Argent" values={[true, true, true, true, true, false]} />
          <SingleTable type="compensations" title="Médaille d'Or" values={[true, true, true, true, true, true]} />
        </>
      ) : (
        <table className="compensations-table">
          <tbody>
            <tr className="cols-headers">
              <th>Contreparties</th>
              <th>Don de Base</th>
              <th className="th-img">
                <img alt="" className="medal" src="/img/medals/bronze.png" />
                <p>Médaille de Bronze</p>
              </th>
              <th className="th-img">
                <img alt="" className="medal" src="/img/medals/silver.png" />
                <p>Médaille d'Argent</p>
              </th>
              <th className="th-img">
                <img alt="" className="medal" src="/img/medals/gold.png" />
                <p>Médaille d'Or</p>
              </th>
            </tr>
          </tbody>

          <TableRow title="Mise en avant sur réseaux sociaux" values={[true, true, true, true]} />
          <TableRow title="Invitations gratuites à nos manifestations" values={[true, true, true, true]} />
          <TableRow
            title="Votre logo sur notre site et nos supports de communication"
            values={[false, true, true, true]}
          />
          <TableRow
            title="Lien vers votre site Internet, ou page partenaire dédiée"
            values={[false, false, true, true]}
          />
          <TableRow
            title="Des actions gratuites (promotionnelles), conférences santé, sensibilisation des collaborateurs"
            values={[false, false, false, true]}
          />
        </table>
      )}
    </>
  );
};

export default CompensationsTable;
