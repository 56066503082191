import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

const TableRow = ({ title, values }) => {
  return (
    <tbody>
      <tr>
        <th className="row-title">
          {title === "Médaille d'Or" && <img className="medal" alt="" src="/img/medals/gold.png" />}
          {title === "Médaille d'Argent" && <img className="medal" alt="" src="/img/medals/silver.png" />}
          {title === "Médaille de Bronze" && <img className="medal" alt="" src="/img/medals/bronze.png" />}

          {title}
        </th>

        {values.map((value, index) => {
          if (typeof value === "boolean") {
            return (
              <td key={index}>{value ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faXmark} />}</td>
            );
          } else {
            return <td key={index}>{value ? value + " €" : "/"}</td>;
          }
        })}
      </tr>
    </tbody>
  );
};

const SingleTable = ({ type, title, values }) => {
  return (
    <>
      <div className="table-title">
        {title === "Médaille d'Or" && <img className="medal" alt="" src="/img/medals/gold.png" />}
        {title === "Médaille d'Argent" && <img className="medal" alt="" src="/img/medals/silver.png" />}
        {title === "Médaille de Bronze" && <img className="medal" alt="" src="/img/medals/bronze.png" />}

        <h5>{title}</h5>
      </div>

      {type === "levels" && (
        <table className="levels-table">
          <TableRow title="Minimum *" values={[values[0]]} />
          <TableRow title="Après déduction fiscale" values={[values[1]]} />
          <TableRow title="Valeur contreparties" values={[values[2]]} />
          <TableRow title="Coût réel" values={[values[3]]} />
        </table>
      )}

      {type === "compensations" && (
        <table className="compensations-table">
          <TableRow title="Mise en avant sur réseaux sociaux" values={[values[1]]} />
          <TableRow title="Invitations gratuites à nos manifestations" values={[values[2]]} />
          <TableRow title="Votre logo sur notre site et nos supports de communication" values={[values[3]]} />
          <TableRow title="Lien vers votre site Internet, ou page partenaire dédiée" values={[values[4]]} />
          <TableRow
            title="Des actions gratuites (promotionnelles), conférences santé, sensibilisation des collaborateurs"
            values={[values[5]]}
          />
        </table>
      )}
    </>
  );
};

export { SingleTable, TableRow };
