import React from "react";
import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";

Font.register({
  family: "Tahoma",
  fonts: [{ src: "/fonts/TAHOMA_0.TTF" }, { src: "/fonts/TAHOMAB0.TTF", fontWeight: 800 }],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Tahoma",
    flexDirection: "column",
    backgroundColor: "white",
    padding: "10px",
  },
  section: {
    paddingRight: "70px",
    paddingLeft: "70px",
    paddingBottom: "40px",
    paddingTop: "40px",
  },
  tablesection: {
    marginBottom: "10px",
    marginTop: "30px",
  },
  cdsection: {
    display: "flex",
    flexDirection: "row",
    marginTop: "50px",
  },
  title: {
    fontSize: 30,
    fontWeight: "900",
    color: "#0E3083",
    marginBottom: "15px",
  },
  subtitle: {
    fontSize: 20,
    fontWeight: "900",
    marginBottom: "10px",
  },
  subsubtitle: {
    fontSize: 14,
    fontWeight: "900",
    marginBottom: "10px",
    color: "#0E3083",
    textAlign: "center",
  },
  paragraph: {
    fontSize: 12,
    marginBottom: "15px",
  },
  logo: {
    height: "70px",
    // width: "200px",
  },
  col: {
    width: "60%",
    backgroundColor: "#0E3083",
    color: "white",
    padding: "10px",
    marginRight: "15px",
  },
  singleline: {
    width: "100%",
    textAlign: "center",
    fontSize: 10,
    marginTop: "10px",
  },
  image: {
    margin: "15px 0",
  },
});

const Pdf = ({ project }) => {
  console.log(project);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>{project.title}</Text>

          <Text style={styles.subtitle}>Présentation</Text>
          <Text style={styles.paragraph}>{project.presentation}</Text>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {project.stakes?.length !== 0 && (
            <>
              <Text style={styles.subtitle}>Les enjeux</Text>
              <View style={styles.paragraph}>
                {project.stakes?.map((stake, index) => (
                  <>
                    <Text>{stake.body}</Text>
                    {stake.image_url && <Image style={styles.image} src={stake.image_url} />}
                  </>
                ))}
              </View>
            </>
          )}
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.subtitle}>Mécénat</Text>
          <Text style={styles.paragraph}>
            Chaque fois que vous versez un don sur ce projet, vous recevez un reçu fiscal pour votre future déclaration
            d’impôts. Les tableaux présentés page suivante vous donnent un aperçu du coût réel de votre don, et des
            contreparties répondant aux enjeux de votre entreprise, ou votre association.
          </Text>

          <Text style={styles.subtitle}>Qui contacter ?</Text>
          <Text style={styles.paragraph}>{project.contact_mail}</Text>

          <View style={styles.cdsection}>
            <View style={styles.col}>
              <Text style={styles.paragraph}>
                CD Maine et Loire Sports Pour Tous 7 rue Pierre de Coubertin 49130 Les Ponts de Cé, FRANCE
              </Text>
              <Text style={styles.paragraph}>
                Téléphone : 02 41 79 49 85 (tous les matins de 9h à 12h30 - le mercredi de 13h30 à 17h30)
              </Text>
              <Text style={styles.paragraph}>Mail : cd49@sportspourtous.org</Text>
            </View>
            <Image style={styles.logo} src="/img/logo.png" />
          </View>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.tablesection}>
          <Text style={styles.subsubtitle}>Les Paliers de Dons</Text>
          <Image src="/img/tables/paliers.png" />
          <Text style={styles.singleline}>* Valeur inscrite sur le reçu fiscal</Text>
        </View>

        <View style={styles.tablesection}>
          <Text style={styles.subsubtitle}>Les Contreparties Possibles</Text>
          <Image src="/img/tables/contreparties.png" />
        </View>
      </Page>
    </Document>
  );
};

export default Pdf;
