import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug } from "@fortawesome/free-solid-svg-icons";

const ErrorPage = ({ message, code }) => {
  return (
    <div className="custom-error page">
      <h2 className="pagetitle">Oups ! Erreur {code}</h2>
      <h3 className="pagetitle">{message}</h3>

      <hr />

      <a href="/" className="send-btn nav-link">
        revenir à l'accueil
      </a>

      <a
        href="mailto:margaleon@tutanota.com?subject=Site CSPT - Problème Technique"
        target="_blank"
        rel="noreferrer"
        className="blue-link"
      >
        <FontAwesomeIcon icon={faBug} /> Signaler un problème technique ?
      </a>
    </div>
  );
};

export default ErrorPage;
