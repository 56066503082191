import axios from "axios";
import React, { useEffect, useState } from "react";
import ConfirmBox from "../popups/ConfirmBox";
import MessageBox from "../popups/MessageBox";
import Spinner from "../Spinner";
import MemberForm from "../forms/MemberForm";

const MembersList = () => {
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [action, setAction] = useState("");
  const [updateMode, setUpdateMode] = useState("");

  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    setIsLoading(true);
    setUpdateMode(false);

    const fetchEvents = async () => {
      try {
        const { data } = await axios.get("/api/members");
        setMembers(data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchEvents();
  }, []);

  const askConfirmation = async () => {
    setConfirmBoxVisible(true);
  };

  const deleteMember = async () => {
    try {
      const response = await axios.delete(`/api/members/${selectedMember.id}`);
      if (response.status === 200) {
        setMessageBoxVisible(true);
        setMessageType("success");
        setMessage("Le membre a bien été supprimé.");
      }
    } catch (err) {
      console.log(err);
      setMessageBoxVisible(true);
      setMessageType("error");
      setMessage("Une erreur est survenue.");
    }
  };

  return (
    <div className="objects-list">
      {confirmBoxVisible && (
        <ConfirmBox
          message={"Supprimer ce Membre ?"}
          onConfirm={deleteMember}
          onCancel={(e) => setConfirmBoxVisible(false)}
        />
      )}

      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);
            window.location.reload(true);
          }}
        />
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {Array.isArray(members) &&
            members?.map((member, index) => {
              return (
                <div
                  key={index}
                  className={selectedMember.id === member.id && updateMode ? "list-item selected" : "list-item"}
                  value={member.id}
                >
                  <h3>{member.first_name + " " + member.last_name}</h3>

                  {selectedMember.id === member.id && updateMode ? (
                    <MemberForm action="update" member={selectedMember} />
                  ) : (
                    <div className="buttons-container">
                      <button
                        value="update"
                        className="send-btn"
                        onClick={(e) => {
                          setAction(e.target.value);
                          setSelectedMember(member);
                          setUpdateMode(true);
                        }}
                      >
                        modifier
                      </button>

                      <button
                        value="delete"
                        className="delete-btn"
                        onClick={(e) => {
                          setAction(e.target.value);
                          setSelectedMember(member);
                          askConfirmation();
                        }}
                      >
                        supprimer
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
        </>
      )}

      <MemberForm action="add" />
    </div>
  );
};

export default MembersList;
