import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import MessageBox from "../popups/MessageBox";
import Spinner from "../Spinner";

const ProgressionForm = ({ progression, project, action }) => {
  const navigate = useNavigate();

  const [body, setBody] = useState(progression?.body);
  const [date, setDate] = useState(progression?.date);

  const [validationErrors, setValidationErrors] = useState([]);
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const addProgression = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.post("/api/progressions", {
        project_id: project.id,
        body,
        date,
      });

      if (response.status === 200) {
        showSuccessBox("Le progrès a bien été sauvegardé.");
      }
    } catch (err) {
      handleError(err);
    }
  };

  const updateProgression = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.patch(`/api/progressions/${progression.id}`, {
        body,
        date,
      });

      if (response.status === 200) {
        showSuccessBox("Le progrès a bien été sauvegardé.");
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = async (err) => {
    setIsRequestLoading(false);
    console.log(err);
    if (err.response.data.errors) setValidationErrors(err.response.data.errors);
    else {
      showErrorBox("Une erreur est survenue.");
    }
  };

  const showSuccessBox = async (msg) => {
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("success");
    setMessage(msg);
  };

  const showErrorBox = async (msg) => {
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("error");
    setMessage(msg);
  };

  return (
    <>
      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);
            /* redirect to project page */
            navigate(`/projects/${project.id}`);
            window.location.reload(true);
          }}
        />
      )}

      <form className="edit-form">
        {action === "add" && <h3>Nouveau Progrès</h3>}

        <div className="inputs-container">
          <label className="required" htmlFor="progression_body">
            Contenu
          </label>
          <textarea
            name="progression_body"
            defaultValue={action === "update" ? progression.body : ""}
            placeholder={action === "update" ? progression.body : "Entrez le contenu du progrès"}
            onChange={(e) => {
              setBody(e.target.value);
            }}
          />

          <label className="required" htmlFor="date">
            Date
          </label>
          <p>
            <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> La date doit être au format dd/mm/yyyy.
          </p>
          <input
            type="text"
            name="date"
            defaultValue={action === "update" ? new Date(progression.date).toLocaleDateString("fr-FR") : ""}
            placeholder={action === "update" ? new Date(progression.date).toLocaleDateString("fr-FR") : "dd/mm/yyyy"}
            onChange={(e) => {
              const [day, month, year] = e.target.value.split("/");
              const formattedDate = month + "/" + day + "/" + year;
              setDate(formattedDate);
            }}
          />
        </div>

        {validationErrors.length !== 0 && (
          <div className="validation-errors">
            {validationErrors.map((error, index) => (
              <p key={index} className="wrong-message">
                {error.message}
              </p>
            ))}
          </div>
        )}

        {action === "add" && (
          <button value="ajouter" className="add-btn" onClick={addProgression}>
            {isRequestLoading ? <Spinner size="small" /> : "ajouter"}
          </button>
        )}
      </form>

      {action === "update" && (
        <button value="update" className="send-btn white-btn" onClick={updateProgression}>
          {isRequestLoading ? <Spinner size="small" /> : "sauvegarder"}
        </button>
      )}
    </>
  );
};

export default ProgressionForm;
