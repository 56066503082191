import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faXmark,
  faPowerOff,
  faArrowRightToBracket,
  faPenToSquare,
  faUserPen,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";

const Header = ({ loggedIn, currentUser }) => {
  const navigate = useNavigate();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isScreenSmall, setIsScreenSmall] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1000px)");
    mediaQuery.addEventListener("change", (mediaQuery) => {
      if (mediaQuery.matches) {
        setIsScreenSmall(true);
      } else {
        setIsScreenSmall(false);
      }
    });
    if (mediaQuery.matches) {
      setIsScreenSmall(true);
    } else {
      setIsScreenSmall(false);
    }
  }, []);

  const logout = async () => {
    try {
      await axios.get("/api/users/logout");

      /* redirect to home page */
      navigate("/");
      window.location.reload(true);
    } catch (err) {
      console.log(err);
    }
  };

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  return (
    <>
      <header id="header" /*onClick={() => window.scrollTo(0, 0)}*/>
        <div className="small-menu">
          <div className="logo-container">
            <a target="_blank" rel="noreferrer" href="https://www.sportspourtous.org/">
              <img src="/img/logo-fede.png" alt="logo" />
            </a>
          </div>

          <button className="  burger send-btn" onClick={toggleMenu}>
            {isMenuVisible ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faBars} />}
          </button>
        </div>

        {(!isScreenSmall || isMenuVisible) && (
          <>
            <menu>
              <Link tabIndex="0" to="/">
                Accueil
              </Link>

              <Link tabIndex="0" to="/commitee">
                Le Comité 49
              </Link>
              <Link tabIndex="0" to="/clubs">
                Les Clubs
              </Link>
              <Link tabIndex="0" to="/events">
                Actualités
              </Link>

              {loggedIn ? (
                <button className="logout-btn" onClick={logout}>
                  <FontAwesomeIcon icon={faPowerOff} className="margin-icon" />
                  Déconnexion
                </button>
              ) : (
                <Link tabIndex="0" to="/login">
                  <FontAwesomeIcon icon={faArrowRightToBracket} className="margin-icon" />
                  Connexion
                </Link>
              )}
            </menu>

            <a className="header-link" href="/">
              <h4>Comité Départemental Sports pour Tous Maine-et-Loire</h4>
            </a>
          </>
        )}
      </header>

      {loggedIn && (
        <div className="user-menu">
          {currentUser.is_admin && (
            <Link to="/edit" className="send-btn nav-link">
              <FontAwesomeIcon icon={faPenToSquare} />
            </Link>
          )}

          {currentUser.is_club && (
            <Link to="/club" className="send-btn nav-link">
              <FontAwesomeIcon icon={faUserGroup} />
            </Link>
          )}

          <Link to="/profile" className="send-btn nav-link">
            <FontAwesomeIcon icon={faUserPen} />
          </Link>
        </div>
      )}
    </>
  );
};

export default Header;
