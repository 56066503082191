import React, { useState, useEffect, useRef } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";

mapboxgl.accessToken = "pk.eyJ1IjoibWFyZ2FsZW9uIiwiYSI6ImNsODh0cnRiZjAwb2wzbnFvaDl3ZHprZjkifQ.FvcPlWYgSZGmAxpyXgK5Rg";

const Map = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-0.5253222732805567);
  const [lat, setLat] = useState(47.441534891907715);
  const [zoom, setZoom] = useState(15);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });

    // add zoom and rotation controls to the map.
    map.current.addControl(new mapboxgl.NavigationControl());

    // add marker
    const marker = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map.current);
  });

  return (
    <div className="mapbox">
      <div ref={mapContainer} className="map-container" />
    </div>
  );
};

export default Map;
