import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MessageBox from "../components/popups/MessageBox";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [validationErrors, setValidationErrors] = useState([]);
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const sendResetMail = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("/api/users/forgot-password", { email: email });
      if (response.status === 200) {
        setMessageBoxVisible(true);
        setMessageType("success");
        setMessage(`Un code a été envoyé à l'adresse ${email}.`);
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.errors) setValidationErrors(err.response.data.errors);
      else {
        setMessageBoxVisible(true);
        setMessageType("error");
        setMessage("Une erreur est survenue.");
      }
    }
  };

  return (
    <div className="login page">
      <h2 className="pagetitle">Mot de Passe Oublié</h2>

      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);
            if (messageType === "success") {
              /* redirect to reset password page */
              navigate("/reset-password");
              window.location.reload(true);
            } else {
              window.location.reload(true);
            }
          }}
        />
      )}

      <form className="login-form">
        <div className="form-element">
          <label htmlFor="email">Email</label>
          <input
            name="email"
            type="email"
            placeholder="Votre email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
          ></input>
        </div>
      </form>

      {validationErrors && (
        <div className="validation-errors">
          {validationErrors.map((error, index) => (
            <p key={index} className="wrong-message">
              {error.message}
            </p>
          ))}
        </div>
      )}

      <button className="  send-btn" onClick={sendResetMail}>
        envoyer
      </button>
    </div>
  );
};

export default ForgotPasswordPage;
