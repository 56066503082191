import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import MapPopup from "./MapPopup";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";

mapboxgl.accessToken = "pk.eyJ1IjoibWFyZ2FsZW9uIiwiYSI6ImNsODh0cnRiZjAwb2wzbnFvaDl3ZHprZjkifQ.FvcPlWYgSZGmAxpyXgK5Rg";

const MapClubs = ({ clubs, clubSelected }) => {
  const [defaultLat, setDefaultLat] = useState(47.32545513056872);
  const [defaultLng, setDefaultLng] = useState(-0.5177068069521489);
  const [zoom, setZoom] = useState(9);

  const mapContainer = useRef(null);
  const map = useRef(null);
  const popUpRef = useRef(new mapboxgl.Popup({ offset: 15 }));

  useEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/outdoors-v11",
      center: [defaultLng, defaultLat],
      zoom: zoom,
    });

    // add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl());

    map.on("load", (e) => {
      const addMarkers = (lng, lat, club, place = null) => {
        // add marker for each club
        const el = document.createElement("svg");
        el.className = "marker";
        const marker = new mapboxgl.Marker(el).setLngLat([lng, lat]).addTo(map);

        marker.getElement().addEventListener("click", (e) => {
          // create popup node
          const popupNode = document.createElement("div");
          ReactDOM.render(<MapPopup club={club} place={place} />, popupNode);
          const markerPopup = popUpRef.current.setLngLat([lng, lat]).setDOMContent(popupNode).addTo(map);
          marker.setPopup(markerPopup);
        });
      };

      clubs.forEach((club) => {
        if (club.latitude && club.longitude && club.latitude !== NaN && club.longitude !== NaN) {
          addMarkers(club.longitude, club.latitude, club);
        }
      });
    });
  });

  return (
    <div className="mapbox">
      <div ref={mapContainer} className="map-container" />
    </div>
  );
};

export default MapClubs;
