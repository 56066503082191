import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Map from "./maps/Map";
import FormatEmail from "../utils/FormatEmail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faLocationDot, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer>
      <img src="/img/wave.svg" alt="" />

      <div className="logo-container">
        <img src="/img/logo-center.png" alt="logo" />
      </div>

      <div className="footer-content">
        <div className="footer-block">
          <h3>Contact</h3>
          <p>
            <FontAwesomeIcon icon={faLocationDot} /> CD Maine et Loire Sports Pour Tous <br />
            7 rue Pierre de Coubertin <br />
            49130 Les Ponts de Cé, FRANCE
          </p>
          <p>
            <FontAwesomeIcon icon={faPhone} /> Téléphone : 02 41 79 49 85 (tous les matins de 9h à 12h30 - le mercredi
            de 13h30 à 17h30)
          </p>

          <Link tabIndex="0" to="/contact">
            <FontAwesomeIcon icon={faEnvelope} /> Formulaire de contact
          </Link>

          <a href={"mailto:cd49@sportspourtous.org"} target="_blank" rel="noreferrer">
            Mail: {FormatEmail("cd49@sportspourtous.org")}
          </a>

          <h3>Nos Réseaux</h3>
          <a target="_blank" rel="noreferrer" href="https://sportspourtous-paysdelaloire.org/">
            Sports Pour Tous - Pays de la Loire
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.sportspourtous.org/">
            Fédération Nationale Sports Pour Tous
          </a>
        </div>

        <div className="footer-block">
          {window.WebGLRenderingContext ? (
            <Map />
          ) : (
            <p className="map-error">
              <FontAwesomeIcon icon={faTriangleExclamation} /> Votre navigateur ne supporte pas WebGL, la carte ne peut
              pas s'afficher correctement.
            </p>
          )}
        </div>
      </div>

      <div className="footer-legal">
        <div className="legal">
          <p>© {new Date().getFullYear()} Tous droits réservés</p>-
          <Link tabIndex="0" to="/legals">
            Mentions légales
          </Link>
          -
          <Link tabIndex="0" to="/confidentiality">
            Politique de confidentialité
          </Link>
          -
          <p>
            Site créé et développé par
            <a href="https://niloccolin.fr" target="_blank" rel="noreferrer">
              niloccolin
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
