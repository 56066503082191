import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MessageBox from "../components/popups/MessageBox";

const ResetPasswordPage = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [validationErrors, setValidationErrors] = useState([]);
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const updatePassword = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("/api/users/reset-password", {
        email,
        token,
        password,
        password_confirm: passwordConfirm,
      });
      if (response.status === 200) {
        setMessageBoxVisible(true);
        setMessageType("success");
        setMessage("Le nouveau mot de passe a bien été sauvegardé.");
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.errors) setValidationErrors(err.response.data.errors);
      else {
        setMessageBoxVisible(true);
        setMessageType("error");
        setMessage("Une erreur est survenue.");
      }
    }
  };

  return (
    <div className="login page">
      <h2 className="pagetitle">Nouveau Mot de Passe</h2>

      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);
            if (messageType === "success") {
              /* redirect to login page */
              navigate("/login");
              window.location.reload(true);
            } else {
              window.location.reload(true);
            }
          }}
        />
      )}

      <form className="login-form">
        <div className="form-element">
          <label htmlFor="token">Code de Réinitialisation</label>
          <input
            name="token"
            type="password"
            placeholder="Veuillez entrer le code reçu par mail"
            onChange={(e) => {
              setToken(e.target.value);
            }}
          ></input>
        </div>

        <div className="form-element">
          <label htmlFor="email">Email</label>
          <input
            name="email"
            type="email"
            placeholder="Votre email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></input>
        </div>

        <div className="form-element">
          <label htmlFor="password">Mot de Passe</label>
          <input
            name="password"
            type="password"
            placeholder="Nouveau mot de passe"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          ></input>
        </div>

        <div className="form-element">
          <label htmlFor="password-confirm">Confirmer Mot de Passe</label>
          <input
            name="password-confirm"
            type="password"
            placeholder="Confirmez votre mot de passe"
            onChange={(e) => {
              setPasswordConfirm(e.target.value);
            }}
          ></input>
        </div>
      </form>

      {validationErrors && (
        <div className="validation-errors">
          {validationErrors.map((error, index) => (
            <p key={index} className="wrong-message">
              {error.message}
            </p>
          ))}
        </div>
      )}

      <button className="  send-btn" onClick={updatePassword}>
        envoyer
      </button>
    </div>
  );
};

export default ResetPasswordPage;
