import React, { useEffect, useLayoutEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import EventsList from "../components/lists/EventsList";
import ProjectsList from "../components/lists/ProjectsList";
import TestimonialsList from "../components/lists/TestimonialsList";
import ProgressionsList from "../components/lists/ProgressionsList";
import StakesList from "../components/lists/StakesList";
import ClubsList from "../components/lists/ClubsList";
import ActivitiesList from "../components/lists/ActivitiesList";
import PartnersList from "../components/lists/PartnersList";
import MembersList from "../components/lists/MembersList";
import PdfButton from "../components/PdfButton";

const EditPage = ({ loggedIn, currentUser }) => {
  const [object, setObject] = useState("");
  const [objectSelected, setObjectSelected] = useState("");

  useLayoutEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
    window.scrollTo(0, 0);
  });

  const handleObjectSelection = async (e) => {
    setObject(e.target.value);
    setObjectSelected(true);
  };

  if (!loggedIn) {
    return <Navigate to="/login" replace />;
  } else if (!currentUser.is_admin) {
    return <Navigate to="/" replace />;
  } else
    return (
      <div className="edit page">
        <h2 className="pagetitle">Édition</h2>

        <p className="info">
          Bienvenue sur la page d'édition ! C'est ici que vous pourrez ajouter, modifier et supprimer du contenu.
          <br />
          Besoin d'aide ? Voici un document pour vous aider à prendre en main les fonctionnalités de la page d'édition.
        </p>

        <PdfButton
          doc_url="https://drive.google.com/file/d/1amOjxwe9vZKHy7WmdAvVB86AfpXF9jhf/view?usp=share_link"
          innerText="aide"
        />

        <hr />

        {objectSelected && (
          <>
            <button
              className="  send-btn back"
              onClick={(e) => {
                setObjectSelected(false);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>

            {object === "project" && <ProjectsList />}
            {object === "event" && <EventsList />}
            {object === "testimonial" && <TestimonialsList />}
            {object === "stake" && <StakesList />}
            {object === "progression" && <ProgressionsList />}
            {object === "club" && <ClubsList />}
            {object === "activity" && <ActivitiesList />}
            {object === "partner" && <PartnersList />}
            {object === "member" && <MembersList />}

            <button
              className="  send-btn back"
              onClick={(e) => {
                setObjectSelected(false);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          </>
        )}

        {!objectSelected && (
          <div className="edit-options">
            <div className="options-col">
              <h3>Projets</h3>
              <button value="project" className="  send-btn" onClick={handleObjectSelection}>
                projets
              </button>
              <p>
                <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> Chaque projet a ses propres tables{" "}
                <i>enjeux</i>, <i>témoignages</i> et <i>progrès</i>.
              </p>
              <button value="stake" className="  send-btn" onClick={handleObjectSelection}>
                enjeux
              </button>
              <button value="testimonial" className="  send-btn" onClick={handleObjectSelection}>
                témoignages
              </button>
              <button value="progression" className="  send-btn" onClick={handleObjectSelection}>
                progrès
              </button>

              <hr />

              <h3>Actualités</h3>
              <button value="event" className="  send-btn" onClick={handleObjectSelection}>
                actualités
              </button>
            </div>

            <div className="options-col">
              <h3>Clubs</h3>
              <button value="club" className="  send-btn" onClick={handleObjectSelection}>
                clubs
              </button>
              <button value="activity" className="  send-btn" onClick={handleObjectSelection}>
                activités
              </button>

              <hr />

              <h3>Comité</h3>
              <button value="partner" className="  send-btn" onClick={handleObjectSelection}>
                partenaires
              </button>
              <button value="member" className="  send-btn" onClick={handleObjectSelection}>
                membres
              </button>
            </div>
          </div>
        )}
      </div>
    );
};

export default EditPage;
