import React from "react";
import { Oval } from "react-loader-spinner";

const Spinner = ({ size }) => {
  return (
    <Oval
      height={size === "small" ? 25 : 80}
      width={size === "small" ? 25 : 80}
      color="#0099DC"
      wrapperStyle={{}}
      wrapperClass={size === "small" ? "spinner-small" : "spinner"}
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#A9E9FF"
      strokeWidth={3}
      strokeWidthSecondary={2}
    />
  );
};

export default Spinner;
