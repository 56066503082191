import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashArrowUp, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import MessageBox from "../popups/MessageBox";
import Spinner from "../Spinner";
import CreateFormData from "../../utils/CreateFormData";

const ClubForm = ({ club, action }) => {
  const navigate = useNavigate();

  const [name, setName] = useState(club?.name);
  const [addressLine1, setAddressLine1] = useState(club?.hq_address_line_1);
  const [addressLine2, setAddressLine2] = useState(club?.hq_address_line_2);
  const [postalCode, setPostalCode] = useState(club?.postal_code);
  const [city, setCity] = useState(club?.city);
  const [latitude, setLatitude] = useState(club?.latitude);
  const [longitude, setLongitude] = useState(club?.longitude);
  const [contactMail, setContactMail] = useState(club?.contact_mail);
  const [phone, setPhone] = useState(club?.phone);
  const [websiteUrl, setWebsiteUrl] = useState(club?.website_url);
  const [logoUrl, setLogoUrl] = useState(club?.logo_url);

  const [corrFName, setCorrFName] = useState(club?.corr_f_name);
  const [corrLName, setCorrLName] = useState(club?.corr_l_name);
  const [corrPhone, setCorrPhone] = useState(club?.corr_phone);
  const [corrMail, setCorrMail] = useState(club?.corr_mail);

  const [docsToAddArray, setDocsToAddArray] = useState(club?.documents || []);
  const [docUrl, setDocUrl] = useState("");
  const [docTitle, setDocTitle] = useState("");
  const [docErrorVisible, setDocErrorVisible] = useState(false);

  const [files, setFiles] = useState("");
  const [imagesToDeleteArray, setImagesToDeleteArray] = useState([]);

  const [validationErrors, setValidationErrors] = useState([]);
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);

  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const uploadImages = async (clubId) => {
    try {
      if (clubId) {
        const data = await CreateFormData(files);

        const response = await axios.post(`/api/clubs/${clubId}/upload`, data);
        if (response.status === 200) {
          showSuccessBox("Les informations du club ont bien été sauvegardées.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const addClub = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.post("/api/clubs", {
        name,
        hq_address_line_1: addressLine1,
        hq_address_line_2: addressLine2,
        postal_code: postalCode,
        city,
        latitude,
        longitude,
        contact_mail: contactMail,
        phone,
        website_url: websiteUrl,
        logo_url: logoUrl,
        corr_f_name: corrFName,
        corr_l_name: corrLName,
        corr_phone: corrPhone,
        corr_mail: corrMail,
        documents: docsToAddArray,
      });

      const clubId = response.data.newClub.id;

      if (files?.length !== 0) {
        await uploadImages(clubId);
      } else {
        if (response.status === 200) {
          showSuccessBox("Le club a bien été sauvegardé.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const updateClub = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.patch(`/api/clubs/${club.id}`, {
        name,
        hq_address_line_1: addressLine1,
        hq_address_line_2: addressLine2,
        postal_code: postalCode,
        city,
        latitude,
        longitude,
        contact_mail: contactMail,
        phone,
        website_url: websiteUrl,
        logo_url: logoUrl,
        corr_f_name: corrFName,
        corr_l_name: corrLName,
        corr_phone: corrPhone,
        corr_mail: corrMail,
        imagesToDelete: imagesToDeleteArray,
        documents: docsToAddArray,
      });

      if (files?.length !== 0) {
        await uploadImages(club.id);
      } else {
        if (response.status === 200) {
          showSuccessBox("Les informations du club ont bien été sauvegardées.");
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = async (err) => {
    setIsRequestLoading(false);
    console.log(err);
    if (err.response.data.errors) setValidationErrors(err.response.data.errors);
    else {
      showErrorBox("Une erreur est survenue.");
    }
  };

  const showSuccessBox = async (msg) => {
    setConfirmBoxVisible(false);
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("success");
    setMessage(msg);
  };

  const showErrorBox = async (msg) => {
    setConfirmBoxVisible(false);
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("error");
    setMessage(msg);
  };

  const addDocToList = async () => {
    if (docUrl && docTitle) {
      // check if url is not already in list
      if (docsToAddArray.find((element) => element.doc_url === docUrl) !== undefined) {
        setDocErrorVisible(true);
        return;
      }

      setDocErrorVisible(false);

      setDocsToAddArray((docsToAddArray) => [...docsToAddArray, { doc_url: docUrl, doc_title: docTitle }]);
      setDocUrl("");
      setDocTitle("");
    }
  };

  console.log(club);
  console.log(club?.hq_address_line_1);

  return (
    <>
      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);
            window.location.reload(true);
          }}
        />
      )}

      <form className="edit-form">
        {action === "add" && <h3>Nouveau Club</h3>}

        <div className="inputs-container">
          <label className="required" htmlFor="name">
            Nom du Club
          </label>
          <input
            type="text"
            name="name"
            defaultValue={action === "update" ? club.name : ""}
            placeholder={action === "update" ? club.name : "Entrez le nom du club"}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />

          <div className="form-row">
            <div>
              <label className="required" htmlFor="hq_address_line_1">
                Adresse ligne 1
              </label>
              <input
                type="text"
                name="hq_address_line_1"
                defaultValue={action === "update" ? club.hq_address_line_1 : ""}
                placeholder={action === "update" ? club.hq_address_line_1 : "Entrez l'adresse du club"}
                onChange={(e) => {
                  setAddressLine1(e.target.value);
                }}
              />
            </div>
            <div>
              <label htmlFor="hq_address_line_2">Adresse ligne 2</label>
              <input
                type="text"
                name="hq_address_line_2"
                defaultValue={action === "update" ? club.hq_address_line_2 : ""}
                placeholder={action === "update" ? club.hq_address_line_2 : "Suite de l'adresse du club"}
                onChange={(e) => {
                  setAddressLine2(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="form-row">
            <div>
              <label className="required" htmlFor="postal_code">
                Code Postal
              </label>
              <input
                type="text"
                name="postal_code"
                defaultValue={action === "update" ? club.postal_code : ""}
                placeholder={action === "update" ? club.postal_code : "Entrez le code postal au format XXXXX"}
                onChange={(e) => {
                  setPostalCode(e.target.value);
                }}
              />
            </div>

            <div>
              <label className="required" htmlFor="city">
                Ville
              </label>
              <input
                type="text"
                name="city"
                defaultValue={action === "update" ? club.city : ""}
                placeholder={action === "update" ? club.city : "Entrez la ville"}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </div>
          </div>

          <label>Coordonnées</label>
          <div className="form-row">
            <div>
              <input
                type="number"
                step="0.01"
                name="latitude"
                defaultValue={action === "update" ? club.latitude : ""}
                placeholder={action === "update" ? club.latitude : "Latitude"}
                onChange={(e) => {
                  setLatitude(e.target.value);
                }}
              />
            </div>

            <div>
              <input
                type="number"
                step="0.01"
                name="longitude"
                defaultValue={action === "update" ? club.longitude : ""}
                placeholder={action === "update" ? club.longitude : "Longitude"}
                onChange={(e) => {
                  setLongitude(e.target.value);
                }}
              />
            </div>
          </div>

          <hr />

          <label className="required" htmlFor="contact_mail">
            Mail de Contact
          </label>
          <input
            type="text"
            name="contact_mail"
            defaultValue={action === "update" ? club.contact_mail : ""}
            placeholder={action === "update" ? club.contact_mail : "Entrez un mail de contact"}
            onChange={(e) => {
              setContactMail(e.target.value);
            }}
          />

          <label htmlFor="phone">Téléphone</label>
          <input
            type="tel"
            name="phone"
            defaultValue={action === "update" ? club.phone : ""}
            placeholder={action === "update" ? club.phone : "Entrez un numéro de téléphone"}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />

          <label>Informations du Président</label>

          <div className="form-row small">
            <label htmlFor="corr_f_name">Prénom</label>
            <input
              type="text"
              name="corr_f_name"
              defaultValue={action === "update" ? club.corr_f_name : ""}
              placeholder={action === "update" ? club.corr_f_name : "Entrez le prénom du président du club"}
              onChange={(e) => {
                setCorrFName(e.target.value);
              }}
            />
          </div>
          <div className="form-row small">
            <label htmlFor="corr_l_name">Nom</label>
            <input
              type="text"
              name="corr_l_name"
              defaultValue={action === "update" ? club.corr_l_name : ""}
              placeholder={action === "update" ? club.corr_l_name : "Entrez le nom du président du club"}
              onChange={(e) => {
                setCorrLName(e.target.value);
              }}
            />
          </div>

          <div className="form-row small">
            <label htmlFor="contact_mail">Mail</label>
            <input
              type="text"
              name="corr_mail"
              defaultValue={action === "update" ? club.corr_mail : ""}
              placeholder={action === "update" ? club.corr_mail : "Entrez le mail du président du club"}
              onChange={(e) => {
                setCorrMail(e.target.value);
              }}
            />
          </div>

          <div className="form-row small">
            <label htmlFor="corr_phone">Téléphone</label>
            <input
              type="tel"
              name="corr_phone"
              defaultValue={action === "update" ? club.corr_phone : ""}
              placeholder={action === "update" ? club.corr_phone : "Entrez le téléphone du président du club"}
              onChange={(e) => {
                setCorrPhone(e.target.value);
              }}
            />
          </div>

          <hr />

          <label htmlFor="website_url">URL du site internet</label>
          <input
            type="url"
            name="website_url"
            defaultValue={action === "update" ? club.website_url : ""}
            placeholder={action === "update" ? club.website_url : "Entrez l'URL d'un site web"}
            onChange={(e) => {
              setWebsiteUrl(e.target.value);
            }}
          />

          <label htmlFor="doc_url">Documents</label>

          <p>
            <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> Plusieurs documents peuvent être ajoutés.
            <br />
            Entrez l'url et un titre et appuyez sur + pour l'ajouter à la liste. Le document sera ajouté lorsque vous
            aurez sauvegardé les modifications.
          </p>

          <ul className="add-elements-list">
            {Array.isArray(docsToAddArray) &&
              docsToAddArray.map((doc, index) => {
                return (
                  <li className="element-to-add" key={index}>
                    <button
                      className="delete-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        setDocsToAddArray(docsToAddArray.filter((item) => item.doc_url !== doc.doc_url));
                      }}
                    >
                      {docsToAddArray.includes(doc) ? (
                        <FontAwesomeIcon icon={faTrashArrowUp} />
                      ) : (
                        <FontAwesomeIcon icon={faTrash} />
                      )}
                    </button>

                    <p>{doc.doc_title}</p>
                  </li>
                );
              })}

            <li className="element-to-add">
              <button
                className="send-btn"
                onClick={(e) => {
                  e.preventDefault();
                  addDocToList();
                }}
              >
                +
              </button>
              <input
                type="url"
                name="doc_url"
                value={docUrl}
                placeholder="Entrez l'URL d'un document/PDF"
                onChange={(e) => {
                  setDocUrl(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    addDocToList();
                  }
                }}
              />
              <input
                type="text"
                name="doc_title"
                value={docTitle}
                placeholder="Entrez un titre pour le document"
                onChange={(e) => {
                  setDocTitle(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    addDocToList();
                  }
                }}
              />
            </li>

            {docErrorVisible && <p className="wrong-message">Ce document est déjà dans la liste.</p>}
          </ul>

          <label htmlFor="logo_url">URL du logo</label>
          <input
            type="url"
            name="logo_url"
            defaultValue={action === "update" ? club.logo_url : ""}
            placeholder={action === "update" ? club.logo_url : "Entrez l'URL d'un logo"}
            onChange={(e) => {
              setLogoUrl(e.target.value);
            }}
          />

          <label htmlFor="images">Images</label>
          <div className="thumb-overview">
            {Array.isArray(club) &&
              club.images?.map((image, index) => {
                return (
                  <div key={index} className="thumb-box">
                    <img
                      alt=""
                      src={image.thumb_url}
                      className={imagesToDeleteArray.includes(image) ? "image-to-delete" : ""}
                    />
                    <button
                      className="delete-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        if (imagesToDeleteArray.includes(image)) {
                          setImagesToDeleteArray(imagesToDeleteArray.filter((item) => item.id !== image.id));
                        } else {
                          setImagesToDeleteArray((imagesToDeleteArray) => [...imagesToDeleteArray, image]);
                        }
                      }}
                    >
                      {imagesToDeleteArray.includes(image) ? (
                        <FontAwesomeIcon icon={faTrashArrowUp} />
                      ) : (
                        <FontAwesomeIcon icon={faTrash} />
                      )}
                    </button>
                  </div>
                );
              })}
          </div>

          <p>
            <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> Les fichiers doivent être inférieurs à 5MB.
          </p>

          <input
            type="file"
            name="images"
            multiple
            accept=".jpg"
            onChange={(e) => {
              setFiles(e.target.files);
            }}
          />
        </div>

        {validationErrors.length !== 0 && (
          <div className="validation-errors">
            {validationErrors.map((error, index) => (
              <p key={index} className="wrong-message">
                {error.message}
              </p>
            ))}
          </div>
        )}

        {action === "add" && (
          <button value="ajouter" type="submit" className="add-btn" onClick={addClub}>
            {isRequestLoading ? <Spinner size="small" /> : "ajouter"}
          </button>
        )}
      </form>

      {action === "update" && (
        <button value="update" type="submit" className="send-btn white-btn" onClick={updateClub}>
          {isRequestLoading ? <Spinner size="small" /> : "sauvegarder"}
        </button>
      )}
    </>
  );
};

export default ClubForm;
