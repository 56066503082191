import axios from "axios";
import React, { useEffect, useState } from "react";
import ClubForm from "../forms/ClubForm";
import ConfirmBox from "../popups/ConfirmBox";
import MessageBox from "../popups/MessageBox";
import Spinner from "../../components/Spinner";

const ClubsList = () => {
  const [clubs, setClubs] = useState([]);
  const [selectedClub, setSelectedClub] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [action, setAction] = useState("");
  const [updateMode, setUpdateMode] = useState("");

  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    setIsLoading(true);
    setUpdateMode(false);

    const fetchClubs = async () => {
      try {
        const { data } = await axios.get("/api/clubs");
        setClubs(data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchClubs();
  }, []);

  const askConfirmation = async () => {
    setConfirmBoxVisible(true);
  };

  const deleteClub = async () => {
    try {
      const response = await axios.delete(`/api/clubs/${selectedClub.id}`);
      if (response.status === 200) {
        setMessageBoxVisible(true);
        setMessageBoxVisible(true);
        setMessageType("success");
        setMessage("Le club a bien été supprimé.");
      }
    } catch (err) {
      console.log(err);
      setMessageBoxVisible(true);
      setMessageType("error");
      setMessage("Une erreur est survenue.");
    }
  };

  return (
    <div className="objects-list">
      {confirmBoxVisible && (
        <ConfirmBox
          message={"Supprimer ce Club ?"}
          onConfirm={deleteClub}
          onCancel={(e) => setConfirmBoxVisible(false)}
        />
      )}

      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);
            setConfirmBoxVisible(false);
            window.location.reload(true);
          }}
        />
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {clubs.map((club, index) => {
            return (
              <div
                key={index}
                className={selectedClub.id === club.id && updateMode ? "list-item selected" : "list-item"}
                value={club.id}
              >
                <h3>{club.name}</h3>

                {selectedClub.id === club.id && updateMode ? (
                  <ClubForm action="update" club={selectedClub} />
                ) : (
                  <div className="buttons-container">
                    <button
                      value="update"
                      className="send-btn"
                      onClick={(e) => {
                        setAction(e.target.value);
                        setSelectedClub(club);
                        setUpdateMode(true);
                      }}
                    >
                      modifier
                    </button>

                    <button
                      value="delete"
                      className="delete-btn"
                      onClick={(e) => {
                        setAction(e.target.value);
                        setSelectedClub(club);
                        askConfirmation();
                      }}
                    >
                      supprimer
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </>
      )}

      <ClubForm action="add" />
    </div>
  );
};

export default ClubsList;
