import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import MessageBox from "../popups/MessageBox";
import Spinner from "../Spinner";

const UserForm = ({ user }) => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [email, setEmail] = useState(user.email);

  const [passwordChange, setPasswordChange] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const [validationErrors, setValidationErrors] = useState([]);
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  useEffect(() => {
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setEmail(user.email);
  }, [user]);

  const updateUser = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);

    try {
      const response = await axios.patch(`/api/users/${user.id}`, {
        first_name: firstName,
        last_name: lastName,
        email,
        password_change: passwordChange,
        new_password: newPassword,
        new_password_confirm: newPasswordConfirm,
        password,
      });

      if (response.status === 200) {
        showSuccessBox("L'utilisateur a bien été sauvegardé.");
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = async (err) => {
    setIsRequestLoading(false);
    console.log(err);
    if (err.response.data.errors) setValidationErrors(err.response.data.errors);
    else {
      showErrorBox("Une erreur est survenue.");
    }
  };

  const showSuccessBox = async (msg) => {
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("success");
    setMessage(msg);
  };

  const showErrorBox = async (msg) => {
    setMessageBoxVisible(true);
    setIsRequestLoading(false);
    setMessageType("error");
    setMessage(msg);
  };

  return (
    <>
      {messageBoxVisible && (
        <MessageBox
          type={messageType}
          message={message}
          onClose={(e) => {
            setMessageBoxVisible(false);
            /* redirect to profile page */
            navigate("/profile");
            window.location.reload(true);
          }}
        />
      )}

      <form className="edit-form user-form">
        <h3>Utilisateur</h3>

        <div className="inputs-container">
          <label htmlFor="first_name">Prénom</label>
          <input
            type="text"
            name="first_name"
            defaultValue={user.first_name}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />

          <label htmlFor="last_name">Nom</label>
          <input
            type="text"
            name="last_name"
            defaultValue={user.last_name}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />

          <label className="required" htmlFor="email">
            Email
          </label>
          <input
            type="text"
            name="email"
            defaultValue={user.email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

          <hr />

          <label className="required" htmlFor="password">
            Mot de Passe Actuel
          </label>
          <input
            type="password"
            name="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <label className="required" htmlFor="new_password">
            Nouveau Mot de Passe
          </label>
          <input
            type="password"
            name="new_password"
            onChange={(e) => {
              if (e.target.value.length !== 0) {
                setPasswordChange(true);
              } else {
                setPasswordChange(false);
              }
              setNewPassword(e.target.value);
            }}
          />

          <label className="required" htmlFor="new_password_confirm">
            Confirmer Mot de Passe
          </label>
          <input
            type="password"
            name="new_password_confirm"
            onChange={(e) => {
              if (e.target.value.length !== 0) {
                setPasswordChange(true);
              } else {
                setPasswordChange(false);
              }
              setNewPasswordConfirm(e.target.value);
            }}
          />

          <p>
            <FontAwesomeIcon icon={faCircleInfo} className="circle-info" /> Votre mot de passe actuel est requis pour
            pouvoir en créer un nouveau.
          </p>
        </div>
      </form>

      {validationErrors.length !== 0 && (
        <div className="validation-errors">
          {validationErrors.map((error, index) => (
            <p key={index} className="wrong-message">
              {error.message}
            </p>
          ))}
        </div>
      )}

      <button value="update" className="  send-btn" onClick={updateUser}>
        {isRequestLoading ? <Spinner size="small" /> : "sauvegarder"}
      </button>
    </>
  );
};

export default UserForm;
